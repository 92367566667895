import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import {
  IonContent,
  IonPage,
  IonHeader,
  useIonViewWillLeave,
} from "@ionic/react";
import "./Withdrawal.scss";
import PopUPDelete from "./DeletePopUp";
import Toolbar from "../../components/Toolbar/Toolbar";
import QuestionImg from "../../assets/images/questionImg.svg";
import deleteButton from "../../assets/images/deleteButton.svg";
import PopUPWithdrawalHelp from "./WithdrawalHelpPopUp";
import { connectWithdrawal } from "./Withdrawal.connect";
import { BASE_DEV_URL } from "../../config/api.config";
import FooterDesktop from "../../components/FooterDesktop/FooterDesktop";
import getErrorMessage from "../../helpers/getErrorMessage.helper";
import Verification from "../../components/Verification/Verification";
import SuccessPopUp from "./SuccessWithdrawPopoup";
import eye from "../../assets/images/eye.svg";
import eyeOff from "../../assets/images/eyeOff.svg";

const Withdrawal = ({ user, actions, balance, elementsParams, ...props }) => {
  const history = useHistory();
  const token = localStorage.getItem("id_token");

  const [withdrawAccount, setWithdrawAccount] = useState("");
  const [message, setMessage] = useState("");
  const [digits, setDigits] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [infoIFSC, setInfoIFSC] = useState({});
  const [withdrawalAmount, setwithdrowalAmount] = useState(null);
  const [withdrawalIFSC, setWithdrawalIFSC] = useState();
  const [withdrawalAccNumber, setWithdrawalAccNumber] = useState();
  const [withdrawalAccName, setWithdrawalAccName] = useState();
  const [confirmAccNumber, setConfirmAccNumber] = useState("");
  const [isRulesAccept, setIsRulesAccept] = useState(false);
  const [isNamesMatch, setisNamesMatch] = useState(false);
  const [isEnoughMoney, setIsEnoughMoney] = useState(false);
  const [itemIFSC, setItemIFSC] = useState();
  const [nameMessage, setNameMessage] = useState("");
  const [bankName, setBankName] = useState();
  const [bankMessage, setBankMessage] = useState("");
  const [openPopUpDelete, setOpenPopUpDelete] = useState(false);
  const [openPopUpHelp, setOpenPopUpHelp] = useState(false);
  const [inputesFromHistory, setInputesFromHistory] = useState(false);
  const [inputesCustom, setInputesCustom] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [shake, setShake] = useState(false);
  const [showSuccessPopUP, setShowSuccessPopUP] = useState(false);
  const [userValidationData, setUserValidationData] = useState(null);
  const [otpVerif, setOtpVerif] = useState(false);
  const [needWait, setNeedWait] = useState(false);
  const [moreThenFive, setMoreThenFive] = useState(false);
  const [accNoEroor, setAccNoEroor] = useState("");
  const [showNumber, setShowNumber] = useState(false);
  const [showConfirmNo, setShowConfirmNo] = useState(false);
  const [bankId, setBankId] = useState("");


  useEffect(() => {
    if(!user?.verifytoken) {
      history.goBack();
    }
  }, [user]);

  // console.log("moreThenFive", submitBtnDisabled);
  const data = {
    amount: withdrawalAmount,
    acNo: withdrawalAccNumber,
    IFSC: withdrawalIFSC,
    bankName: bankName,
    branchName: infoIFSC?.BRANCH,
    accountName: withdrawalAccName,
  };

  const getUserVerification = async () => {
    const user = await actions.requestProfile();
    setUserValidationData(user);
    return user;
  };
  useEffect(() => {
    getUserVerification();
  }, []);

  const withrawRequest = async () => {
    // console.log("withrawRequest");
    // const loginName = JSON.parse(localStorage.getItem("user_data") || "{}").loginName
    // if (loginName && !!![9, 10].includes(loginName?.split("_")?.[1]?.length)) {
    //   const user = await actions.requestProfile()
    //   if (!user?.mobile_verified) {
    //     toastr.error('Error', "Please confirm your number")
    //     setShake(true)
    //     setTimeout(() => setShake(false), 600)
    //     return
    //   }
    // }
    // const user = await getUserVerification();
    // if (!user?.mobile_verified) {
    //   toastr.error("Error", "Please confirm your number");
    //   setShake(true);
    //   setTimeout(() => setShake(false), 600);
    //   return;
    // }
    if (!isRulesAccept && isEnoughMoney && !digits && isNamesMatch) {
      console.log(
        "rules accepted",
        isRulesAccept,
        "amount",
        withdrawalAmount,
        "IFSC",
        withdrawalIFSC,
        "accNumber",
        withdrawalAccNumber,
        "Accname",
        withdrawalAccName
      );
      setSubmitBtnDisabled(true);

      let withdrawData = {
        type: "bank",
        amount: data.amount,
        paymentId: withdrawAccount,
        managerId: "",
        managertype: "",
      };


      return await axios
        .post(BASE_DEV_URL + "withdrawalPayment", withdrawData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (!response.data.success) {
            setNeedWait(true);
            setSubmitBtnDisabled(false);
            toastr.error("Error", response?.data?.message);
          } else {
            setTimeout(() => {
              setSubmitBtnDisabled(false);
              setShowSuccessPopUP(true);
            }, 500);
          }
        })
        // .catch((error) => toastr.error('Error', error?.response?.data?.result))
        .catch((error) => {
          setNeedWait(true);
          setSubmitBtnDisabled(false);
          toastr.error("Error", error?.response?.data?.error);
          const errorMsg = getErrorMessage(error);
          throw errorMsg;
        });
    }
  };

  useEffect(() => {
    if (needWait) {
      setTimeout(() => {
        setInputesCustom(false);
        setwithdrowalAmount(null);
        setWithdrawalIFSC("");
        setWithdrawalAccName("");
        setisNamesMatch(false);
        setWithdrawalAccNumber(null);
        setConfirmAccNumber(null);
        setAccountNumber("");
        setNeedWait(false);
      });
    }
  }, [needWait]);

  useEffect(() => {
    codeAcc();
  }, []);

  const codeIFSC = async (e) => {
    return await axios
      .get(BASE_DEV_URL + `payments/userid/ifsc?ifsc=${e}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => setInfoIFSC(response.data.result))
      .catch((e) => {
        setInfoIFSC();
        setDigits("Invalid IFSC code");
        const errorMsg = getErrorMessage(e);
        throw errorMsg;
      });
  };
  const [prevAccounts, setPrevAccounts] = useState();

  const addWithrawMethod = async () => {
    console.log("Add withrawRequest");

    if (
      !isRulesAccept &&
      isEnoughMoney &&
      withdrawalIFSC?.length === 11 &&
      !digits &&
      withdrawalAccNumber &&
      isNamesMatch &&
      withdrawalAccName
    ) {
      console.log(
        "rules accepted",
        isRulesAccept,
        "amount",
        withdrawalAmount,
        "IFSC",
        withdrawalIFSC,
        "accNumber",
        withdrawalAccNumber,
        "Accname",
        withdrawalAccName
      );
      setSubmitBtnDisabled(true);

      let paymentData = {
        type: "bank",
        name: data.accountName,
        bankName: data.bankName,
        upi: "",
        accnumber: data.acNo,
        ifsc: data.IFSC.toString()?.toUpperCase(),
        withdrawlId: bankId,
      };

      // console.log('token: ', token);
      return await axios
        .post(BASE_DEV_URL + "withdrawalMethod", paymentData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (response) => {
          // console.log('Response Bolte: ', response)
          let withdrawData = {
            type: "bank",
            amount: data.amount,
            paymentId: response.data.doc._id,
            managerId: "",
            managertype: "",
          };

          return await axios
            .post(BASE_DEV_URL + "withdrawalPayment", withdrawData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              if (!response.data.success) {
                setNeedWait(true);
                setSubmitBtnDisabled(false);
                toastr.error("Error", response?.data?.message);
              } else {
                setTimeout(() => {
                  setSubmitBtnDisabled(false);
                  setShowSuccessPopUP(true);
                }, 500);
              }
            })
            .catch((error) => {
              setNeedWait(true);
              setSubmitBtnDisabled(false);
              toastr.error("Error", error?.response?.data?.error);
              const errorMsg = getErrorMessage(error);
              throw errorMsg;
            });
        })
        .catch((error) => {
          setNeedWait(true);
          setSubmitBtnDisabled(false);
          toastr.error("Error", error?.response?.data?.error);
          const errorMsg = getErrorMessage(error);
          throw errorMsg;
        });
    }
  };

  useEffect(() => {
    if (prevAccounts?.length >= 5) {
      setMoreThenFive(true);
    } else {
      setMoreThenFive(false);
    }
  }, [prevAccounts?.length]);

  const onMoreThenFive = () => {
    setInputesCustom(false);
    setInputesFromHistory(true);
    toastr.error(
      "You have reached a limit of 5 withdrawal accounts. Kindly delete any one from the list to add another"
    );
  };

  useEffect(() => {
    const sameAccount = prevAccounts?.find(
      (item) =>
        item?.ifsc === withdrawalIFSC && item?.accNumber === withdrawalAccNumber
    );
    if (inputesCustom && sameAccount) {
      toastr.error("This account is already exists");
      setSubmitBtnDisabled(true);
    } else {
      setSubmitBtnDisabled(false);
    }
  }, [withdrawalIFSC, withdrawalAccNumber]);

  // console.log("prevAccounts", prevAccounts);

  useEffect(() => {
    setInputesCustom(false);
    setwithdrowalAmount(null);
    setWithdrawalIFSC("");
    setWithdrawalAccName("");
    setisNamesMatch(false);
    setWithdrawalAccNumber(null);
    setConfirmAccNumber(null);
    setAccountNumber("");
    setAccNoEroor("");
  }, [history.location.pathname]);

  const codeAcc = async () => {
    return await axios
      .get(BASE_DEV_URL + "getwithdrawnMethod", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          const bankData = response.data.data.find(
            (item) => item.type === "bank"
          );

          // console.log("Bank Id: ", bankData._id)
          setBankId(bankData._id);

          bankData.withdrawns
            ? setPrevAccounts(bankData.withdrawns)
            : toastr.info("", "You have no accounts yet");
        } else {
          toastr.info("", "You have no accounts yet");
          setPrevAccounts();
        }
      })
      .catch((e) => {
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      });
  };

  useEffect(() => {
    setTimeout(() => {
      if (itemIFSC?.IFSCCode?.length === 11) {
        codeIFSC(itemIFSC?.IFSCCode);
      }
    });
  }, [itemIFSC?.IFSCCode]);

  useEffect(() => {
    if (prevAccounts) {
      setWithdrawalIFSC();
      setWithdrawalAccName();
      setisNamesMatch(false);
      setWithdrawalAccNumber();
      setWithdrawalIFSC(itemIFSC?.IFSCCode);
      setWithdrawalAccName(itemIFSC?.accountName);
      setisNamesMatch(true);
      setWithdrawalAccNumber(itemIFSC?.accountNo);
    }
  }, [itemIFSC]);

  const onValidationAmount = (e) => {
    e = e.replace(/[^\d]/g, "");
    setwithdrowalAmount(e);
    if (balance?.balance < e) {
      setMessage("You don`t have enough money on your balance");
      // setTimeout(() => {
      //   setMessage('')
      // }, 3000)
      // setIsEnoughMoney(false)
    } else if (e < 0) {
      // setwithdrowalAmount(e)
      setMessage("Amount can't be negative");
      setIsEnoughMoney(false);
    } else if (e < 1) {
      // setwithdrowalAmount(e)
      setMessage("Amount can't be empty");
      setIsEnoughMoney(false);
    } else if (e < 300) {
      // setwithdrowalAmount(e)
      setMessage("Amount can't be less than 300");
      setIsEnoughMoney(false);
    } else {
      // setwithdrowalAmount(e)
      setMessage("");
      setIsEnoughMoney(true);
    }
  };

  const messageNoBalance = () => {
    setMessage("You don`t have enough money on your balance");
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const onValidationIFSC = (e) => {
    setWithdrawalIFSC(e);
    const regex = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
    const allZeros = /^[A-Za-z]{4}0000000$/;
    if (!/^[A-Z]{4}/.test(e)) {
      setDigits("The first four characters must be alphabetic");
      setInfoIFSC();
    } else if (e[4] != 0) {
      setDigits("The fifth character must be 0");
      setInfoIFSC();
    } else if (!/^[A-Za-z0-9]{6}$/.test(e?.slice(5))) {
      setDigits("The last six characters must be numeric or alphabetic");
      setInfoIFSC();
    } else {
      setDigits("");
      setInfoIFSC();
    }
    if (e?.length > 11) {
      setDigits("IFSC should be 11 digits");
      setInfoIFSC();
    }
    if (regex.test(e) && allZeros.test(e)) {
      setDigits("IFSC code cannot consist solely of zeros");
      setInfoIFSC();
    }
    if (regex.test(e) && !allZeros.test(e)) {
      codeIFSC(e);
      setDigits("");
    }
  };

  const onNewValidationIFSC = (e) => {
    setWithdrawalIFSC(e);
    const regex = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
    const allZeros = /^[A-Za-z]{4}0000000$/;
    if (!/^[A-Z]{4}/.test(e)) {
      setDigits("The first four characters must be alphabetic");
      setInfoIFSC();
    } else if (e[4] != 0) {
      setDigits("The fifth character must be 0");
      setInfoIFSC();
    } else if (!/^[A-Za-z0-9]{6}$/.test(e?.slice(5))) {
      setDigits("The last six characters must be numeric or alphabetic");
      setInfoIFSC();
    } else {
      setDigits("");
      setInfoIFSC();
    }
    if (e?.length > 11) {
      setDigits("IFSC should be 11 digits");
      setInfoIFSC();
    }
    if (regex.test(e) && allZeros.test(e)) {
      setDigits("IFSC code cannot consist solely of zeros.");
      setInfoIFSC();
    }
    if (regex.test(e) && !allZeros.test(e)) {
      codeIFSC(e);
      setDigits("");
    }
  };

  const onAccNumber = (e) => {
    e = e.replace(/[^\d]/g, "");
    setWithdrawalAccNumber(e);
    if (e.length <= 5) {
      setAccNoEroor("Account number should be more than 5 digits");
    } else {
      setAccNoEroor("");
    }
  };

  const onAccName = (e) => {
    e = e.replace(/[^A-Za-z ]/g, "");
    if (e.length < 32) {
      setWithdrawalAccName(e);
      setNameMessage("");
    } else {
      setNameMessage("Account name should be less than 32 digits");
    }
  };

  const onBankName = (e) => {
    e = e.replace(/[^A-Za-z ]/g, "");
    if (e.length < 32) {
      setBankName(e);
      setBankMessage("");
    } else {
      setBankMessage("Account name should be less than 32 digits");
    }
  };

  const onNewAccName = (e) => {
    if (e.length < 32) {
      setWithdrawalAccName(e);
      setNameMessage("");
    } else {
      setNameMessage("Account name should be less than 32 digits");
    }
  };

  useEffect(() => {
    if (confirmAccNumber?.length && withdrawalAccNumber !== confirmAccNumber) {
      setAccountNumber("Account number should match");
    } else {
      setAccountNumber("");
    }
  }, [withdrawalAccNumber, confirmAccNumber]);

  const onValidationAccnumber = (e) => {
    e = e.replace(/[^\d]/g, "");
    setConfirmAccNumber(e);
    // console.log('onValidationAccnumber', e)
    if (e.length > 0 && e !== withdrawalAccNumber) {
      setAccountNumber("Account number should match");
      setisNamesMatch(false);
      // console.log('isNamesMatch', isNamesMatch)
    } else {
      setAccountNumber("");
      setisNamesMatch(true);
      // console.log(isNamesMatch)
    }
  };

  useEffect(() => {
    if (inputesCustom) {
      setWithdrawalAccNumber("");
      setWithdrawalAccName("");
      setWithdrawalIFSC();
      setisNamesMatch(false);
      setWithdrawAccount("");
      setItemIFSC({});
      setIsRulesAccept(false);
      setInfoIFSC();
      setDigits();
      setAccountNumber("");
      setAccNoEroor("");
      setConfirmAccNumber("");
    }
  }, [inputesCustom]);

  useEffect(() => {
    if (inputesFromHistory) {
      setWithdrawalAccNumber("");
      setWithdrawalAccName("");
      setWithdrawalIFSC();
      setisNamesMatch(false);
      setWithdrawAccount("");
      setItemIFSC({});
      setIsRulesAccept(false);
      setInfoIFSC();
      setDigits();
      setAccountNumber("");
      setAccNoEroor("");
      setConfirmAccNumber("");
    }
  }, [inputesFromHistory]);

  useEffect(() => {
    return () => {
      setInputesCustom(false);
      setInputesFromHistory(false);
    };
  }, []);

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth;

  // Fields check
  let [withdrawalAmountCheck, setWithdrawalAmountCheck] = useState("");
  let [withdrawalIFSCCheck, setWithdrawalIFSCCheck] = useState("");
  let [withdrawalAccNumberCheck, setWithdrawalAccNumberCheck] = useState("");
  let [withdrawalAccNameCheck, setWithdrawalAccNameCheck] = useState("");
  let [confirmAccNumberCheck, setConfirmAccNumberCheck] = useState("");
  let [isRulesAcceptCheck, setIsRulesAcceptCheck] = useState("");
  let [bankNameCheck, setBankNameCheck] = useState("");

  const fieldCheck = () => {
    !withdrawalAmount
      ? setWithdrawalAmountCheck(false)
      : setWithdrawalAmountCheck("");
    !withdrawalIFSC
      ? setWithdrawalIFSCCheck(false)
      : setWithdrawalIFSCCheck("");
    !withdrawalAccNumber
      ? setWithdrawalAccNumberCheck(false)
      : setWithdrawalAccNumberCheck("");
    !withdrawalAccName
      ? setWithdrawalAccNameCheck(false)
      : setWithdrawalAccNameCheck("");
    !bankName ? setBankNameCheck(false) : setBankNameCheck("");
    confirmAccNumber.length < 1
      ? setConfirmAccNumberCheck(false)
      : setConfirmAccNumberCheck("");
    !isRulesAccept ? setIsRulesAcceptCheck(false) : setIsRulesAcceptCheck("");
  };
  // End Fields check

  return (
    <IonPage className={openPopUpHelp && "scrollOff ion-page-invisible"}>
      {width < 1024 ? (
        <IonHeader>
          <Toolbar />
        </IonHeader>
      ) : (
        <div style={{ height: "135px" }}></div>
      )}
      {width < 1024 ? (
        <IonContent className="ChangePassword" fullscreen>
          <form action="">
            <div className="withdraw-screen">
              <div className="withdraw-desktop">
                <div className="withdraw-desktop__rightpage">
                  <div className="backgroundInputs">
                    <div className="withdraw-desktop__leftpage">
                      <h2 className="page-title">Withdraw funds</h2>
                      {/* <div
                  className="page-help"
                  onClick={() => setOpenPopUpHelp(true)}
                >
                  <span className="page-help__text">Help</span>
                  <img loading={"lazy"} className="page-help__img" src={QuestionImg} alt="" />
                </div> */}
                    </div>
                    <div className="page-subtitle-notes">
                      <p>
                        1. This form is for withdrawing the amount from the main
                        wallet only.
                      </p>
                      <p>
                        2. The bonus wallet amount cannot be withdrawn by this
                        form.
                      </p>
                      <p>
                        3. Do not put Withdraw request without betting with
                        deposit amount. Such activity will be identified as
                        Suspicious
                      </p>
                      <p>
                        4. If multiple users are using same withdraw account
                        then all the linked users will be blocked.
                      </p>
                      <p>5. Paytm account numbers always start with 91</p>
                    </div>
                  </div>
                  <div className="page-subtitlee subtitle-hidden">
                    Please fill in all required fields
                  </div>
                  <div className="buttons-flex">
                    {width >= 1024 ? (
                      <button
                        type="button"
                        onClick={() => {
                          setInputesCustom(true);
                          setInputesFromHistory(false);
                          setWithdrawAccount();
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge refreshBtn"></span>
                        <span className="front refreshBtn">
                          Use New Account
                        </span>
                      </button>
                    ) : (
                      <div
                        className="withdraw-account__newitem "
                        onClick={() => {
                          setInputesCustom(true);
                          setInputesFromHistory(false);
                          setWithdrawAccount();
                          setIsRulesAccept(true);
                        }}
                      >
                        <div className="withdraw-account__newitem-title">
                          Use New Account
                        </div>
                      </div>
                    )}
                    {width >= 1024 ? (
                      <button
                        type="button"
                        onClick={() => {
                          setInputesCustom(false);
                          setInputesFromHistory(true);
                          codeAcc();
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge refreshBtn"></span>
                        <span className="front refreshBtn">
                          Use Previous Account
                        </span>
                      </button>
                    ) : (
                      <div
                        className="withdraw-account__newitem"
                        onClick={() => {
                          setInputesCustom(false);
                          setInputesFromHistory(true);
                          codeAcc();
                        }}
                      >
                        <div className="withdraw-account__newitem-title">
                          Use Previous Account
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="withdraw-account">
                    {/* TODO: map items and dispay data from api if exists */}

                    <div className="withdraw-account__flex">
                      {inputesFromHistory && prevAccounts?.length > 0
                        ? prevAccounts.map((item) => {
                            return (
                              <label
                                htmlFor={item._id}
                                className={
                                  withdrawAccount === item._id
                                    ? "withdraw-account__item-checked"
                                    : "withdraw-account__item"
                                }
                                onClick={() => {
                                  setWithdrawAccount(item._id);
                                  setInputesFromHistory(true);
                                  setInputesCustom(false);
                                }}
                              >
                                <div
                                  className="withdraw-account__item-content"
                                  onClick={() => setItemIFSC(item)}
                                >
                                  <div>
                                    <div className="withdraw-account__item-content__account">
                                      {item.name?.length > 15
                                        ? item.name.slice(0, 15) + "..."
                                        : item.name}
                                    </div>
                                    <div className="withdraw-account__item-content__accountNo">
                                      {item.accnumber}
                                    </div>
                                    <div className="withdraw-account__item-content__IFSC">
                                      {item.bankName}
                                      <input
                                        className="check-account"
                                        type="radio"
                                        name="choosenaccount"
                                        checked={withdrawAccount === item._id}
                                        id={item._id}
                                      />
                                      <img
                                        loading={"lazy"}
                                        className="delete-account"
                                        src={deleteButton}
                                        alt="deleteButton"
                                        onClick={() =>
                                          withdrawAccount === item._id &&
                                          setOpenPopUpDelete(true)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </label>
                            );
                          })
                        : null}
                    </div>
                  </div>

                  {/* PREVIOUS ACCOUNTS */}

                  {inputesFromHistory && itemIFSC && prevAccounts && (
                    <div className="withdraw-desktop__rightpage-inputcontent">
                      <div className="backgroundInputs">
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Amount</div>
                          <input
                            id="Amount"
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Amount"
                            value={withdrawalAmount}
                            onChange={(e) => onValidationAmount(e.target.value)}
                          />
                          <span className="withdraw-textarea__currency">
                            INR
                          </span>
                          <span className="withdraw-textarea__currencyy">
                            ₹
                          </span>
                          <div className="withdraw-amaount-buttons">
                            {width >= 1024 ? (
                              <>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 1000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 1000
                                      );
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +1,000
                                  </span>
                                </button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 5000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 5000
                                      );
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +5,000
                                  </span>
                                </button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 10000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 10000
                                      );
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +10,000
                                  </span>
                                </button>
                              </>
                            ) : (
                              <>
                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 1000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 1000
                                      );
                                    }
                                  }}
                                >
                                  +1,000
                                </div>

                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 5000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 5000
                                      );
                                    }
                                  }}
                                >
                                  +5,000
                                </div>
                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 10000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 10000
                                      );
                                    }
                                  }}
                                >
                                  +10,000
                                </div>
                              </>
                            )}
                          </div>
                          <div className="validation-message">{message}</div>
                        </div>
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">IFSC Code</div>
                          <input
                            className="withdraw-textarea uppercaseIFSC"
                            type="text"
                            placeholder="eg. IFSC0000001"
                            // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                            onChange={(e) =>
                              onValidationIFSC(e.target.value.toUpperCase())
                            }
                            // defaultValue={itemIFSC?.IFSCCode}
                            disabled={true}
                            value={itemIFSC?.ifsc}
                          />
                          <div className="validation-message">{digits}</div>
                          {infoIFSC ? (
                            <div className="withdraw-textarea__description">
                              {infoIFSC?.BANK}, {infoIFSC?.BRANCH},{" "}
                              {infoIFSC?.CITY}
                            </div>
                          ) : null}
                        </div>
                        <div className="withdraw-input-content ">
                          <div className="page-subtitle">Account number</div>
                          <input
                            id="account"
                            className={`withdraw-textarea ${
                              !showNumber && "hidden-accnumber"
                            }`}
                            type={width < 1024 ? "tel" : "text"}
                            placeholder="Enter Accout Number"
                            disabled={true}
                            onChange={(e) => onAccNumber(e.target.value)}
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 18))
                            }
                            value={
                              itemIFSC?.accnumber
                                ? itemIFSC?.accnumber
                                : withdrawalAccNumber
                            }
                          />
                          <img
                            className="eyeIconWithdrawal"
                            src={showNumber ? eye : eyeOff}
                            alt="eyeIcon"
                            onClick={() => setShowNumber(!showNumber)}
                          />
                          <div className="validation-message">{accNoEroor}</div>
                        </div>
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">
                            Confirm account number
                          </div>
                          <input
                            className={`withdraw-textarea ${
                              !showConfirmNo && "hidden-accnumber"
                            }`}
                            type={width < 1024 ? "tel" : "text"}
                            placeholder="Enter Accout Number"
                            // disabled={true}
                            onChange={(e) =>
                              onValidationAccnumber(e.target.value)
                            }
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 18))
                            }
                            value={
                              itemIFSC?.accnumber
                                ? itemIFSC?.accnumber
                                : confirmAccNumber
                            }
                          />
                          <img
                            className="eyeIconWithdrawal"
                            src={showConfirmNo ? eye : eyeOff}
                            alt="eyeIcon"
                            onClick={() => setShowConfirmNo(!showConfirmNo)}
                          />
                          <div className="validation-message">
                            {accountNumber}
                          </div>
                        </div>
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Account name</div>
                          <input
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Accout Name"
                            onChange={(e) => onAccName(e.target.value)}
                            disabled={true}
                            value={
                              itemIFSC?.name
                                ? itemIFSC?.name
                                : withdrawalAccName
                            }
                            maxLength={32}
                          />
                          {/* <div className="validation-message">{nameMessage}</div> */}
                        </div>
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Bank name</div>
                          <input
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Bank Name"
                            onChange={(e) => onBankName(e.target.value)}
                            disabled={true}
                            value={
                              itemIFSC?.bankName ? itemIFSC?.bankName : bankName
                            }
                            maxLength={32}
                          />
                          {/* <div className="validation-message">{nameMessage}</div> */}
                        </div>
                      </div>
                      {/* <Verification
                        submit={() => setOtpVerif(true)}
                        shake={shake}
                        home={false}
                      /> */}
                      <div className="rulesAccept">
                        {/* <input
                      className="rulesAccept__checkbox"
                      type="checkbox"
                      id="confirmWithdraw"
                      checked={isRulesAccept}
                      onChange={() => setIsRulesAccept(!isRulesAccept)}
                    /> */}
                        <div
                          className={`rulesAccept__checkboxW ${
                            !isRulesAccept
                              ? "rulesAccept__checkbox_checked"
                              : ""
                          }`}
                          id="confirmWithdraw"
                          onClick={() => setIsRulesAccept(!isRulesAccept)}
                        />
                        <label htmlFor="confirmWithdraw"></label>
                        <span className="rulesAccept__text">
                          I have read and agree with &nbsp;
                          <span
                            className="rulesAccept__text-underline"
                            onClick={() =>
                              history.push("/termsOfWithdrowalPayment")
                            }
                          >
                            the terms of payment and withdrawal policy.
                          </span>
                        </span>
                      </div>
                      {width >= 1024 ? (
                        <div className="submitBtnW">
                          <button
                            type="button"
                            onClick={() =>
                              !moreThenFive
                                ? withrawRequest()
                                : onMoreThenFive()
                            }
                            className={` 
                          ${
                            isRulesAccept &&
                            isEnoughMoney &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAmount &&
                            !submitBtnDisabled
                              ? "submitValidBtn"
                              : "withdrawalNotValidBtn"
                          }`}
                            disabled={submitBtnDisabled}
                          >
                            {isRulesAccept &&
                            isEnoughMoney &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAmount ? (
                              <>
                                <span className="shadow"></span>
                                <span className="edge"></span>
                                <span className="front">Submit</span>
                              </>
                            ) : (
                              <div className="withdraw-submitNew__text">
                                Submit
                              </div>
                            )}
                          </button>
                        </div>
                      ) : (
                        <button
                          className={
                            !isRulesAccept &&
                            isEnoughMoney &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAmount &&
                            !submitBtnDisabled
                              ? "withdraw-submit"
                              : "withdrawalNotValidBtn"
                          }
                          onClick={() =>
                            !moreThenFive ? withrawRequest() : onMoreThenFive()
                          }
                          type="button"
                          disabled={submitBtnDisabled}
                        >
                          <div className="withdraw-submit__text">Submit</div>
                        </button>
                      )}
                    </div>
                  )}

                  {/* CREATE NEW ACCOUNT INPUTS */}

                  {inputesCustom && (
                    <div className="withdraw-desktop__rightpage-inputcontent">
                      <div className="backgroundInputs">
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Amount</div>
                          <input
                            id="Amount"
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Amount"
                            value={withdrawalAmount}
                            onChange={(e) => onValidationAmount(e.target.value)}
                          />
                          <span className="withdraw-textarea__currency">
                            INR
                          </span>
                          <span className="withdraw-textarea__currencyy">
                            ₹
                          </span>
                          <div className="withdraw-amaount-buttons">
                            {width >= 1024 ? (
                              <>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 1000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 1000
                                      );
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +1,000
                                  </span>
                                </button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 5000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 5000
                                      );
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +5,000
                                  </span>
                                </button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 10000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 10000
                                      );
                                    }
                                  }}
                                  className="pushable"
                                >
                                  <span className="shadow"></span>
                                  <span className="edge refreshBtn"></span>
                                  <span className="front refreshBtn">
                                    +10,000
                                  </span>
                                </button>
                              </>
                            ) : (
                              <>
                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 1000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 1000
                                      );
                                    }
                                  }}
                                >
                                  +1,000
                                </div>

                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 5000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 5000
                                      );
                                    }
                                  }}
                                >
                                  +5,000
                                </div>
                                <div
                                  className="withdraw-amaount-buttons__value"
                                  onClick={() => {
                                    if (
                                      balance?.balance <
                                      +withdrawalAmount + 10000
                                    ) {
                                      messageNoBalance();
                                    } else {
                                      setMessage("");
                                      setIsEnoughMoney(true);
                                      setwithdrowalAmount(
                                        +withdrawalAmount + 10000
                                      );
                                    }
                                  }}
                                >
                                  +10,000
                                </div>
                              </>
                            )}
                          </div>
                          <div className="validation-message">{message}</div>
                        </div>
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">IFSC Code</div>
                          <input
                            className="withdraw-textarea uppercaseIFSC"
                            type="text"
                            // onReset={() => setInputedIFSC()}
                            // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                            onChange={(e) =>
                              onNewValidationIFSC(e.target.value.toUpperCase())
                            }
                            placeholder="eg. IFSC0000001"
                            // defaultValue={itemIFSC?.IFSCCode}
                            // value={itemIFSC?.IFSCCode ? itemIFSC?.IFSCCode : inputedIFSC}
                          />
                          <div className="validation-message">{digits}</div>
                          {infoIFSC ? (
                            <div className="withdraw-textarea__description">
                              {infoIFSC?.BANK}, {infoIFSC?.BRANCH},{" "}
                              {infoIFSC?.CITY}
                            </div>
                          ) : null}
                        </div>
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Account number</div>
                          <input
                            id="account"
                            className={`withdraw-textarea ${
                              !showNumber && "hidden-accnumber"
                            }`}
                            type={width < 1024 ? "tel" : "text"}
                            placeholder="Enter Accout Number"
                            onChange={(e) => onAccNumber(e.target.value)}
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 18))
                            }
                            value={withdrawalAccNumber}

                            // defaultValue={itemIFSC?.accountNo}
                          />
                          <img
                            className="eyeIconWithdrawal"
                            src={showNumber ? eye : eyeOff}
                            alt="eyeIcon"
                            onClick={() => setShowNumber(!showNumber)}
                          />
                          <div className="validation-message">{accNoEroor}</div>
                        </div>
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">
                            Confirm account number
                          </div>
                          <input
                            className={`withdraw-textarea ${
                              !showConfirmNo && "hidden-accnumber"
                            }`}
                            type={width < 1024 ? "tel" : "text"}
                            placeholder="Enter Accout Number"
                            // disabled={!confirmAccNumber?.length && !withdrawalAccNumber}
                            onChange={(e) =>
                              onValidationAccnumber(e.target.value)
                            }
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 18))
                            }
                            value={confirmAccNumber}

                            // defaultValue={itemIFSC?.accountNo}
                          />
                          <img
                            className="eyeIconWithdrawal"
                            src={showConfirmNo ? eye : eyeOff}
                            alt="eyeIcon"
                            onClick={() => setShowConfirmNo(!showConfirmNo)}
                          />
                          <div className="validation-message">
                            {accountNumber}
                          </div>
                        </div>
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Account name</div>
                          <input
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Accout Name"
                            onChange={(e) => onAccName(e.target.value)}
                            value={withdrawalAccName}
                            // defaultValue={itemIFSC?.accountName}
                            maxLength={32}
                          />
                          <div className="validation-message">
                            {nameMessage}
                          </div>
                        </div>
                        <div className="withdraw-input-content">
                          <div className="page-subtitle">Bank name</div>
                          <input
                            className="withdraw-textarea"
                            type="text"
                            placeholder="Enter Bank Name"
                            onChange={(e) => onBankName(e.target.value)}
                            value={bankName}
                            // defaultValue={itemIFSC?.accountName}
                            maxLength={32}
                          />
                          <div className="validation-message">
                            {bankMessage}
                          </div>
                        </div>
                      </div>
                      {/* <Verification
                        submit={() => setOtpVerif(true)}
                        shake={shake}
                        home={false}
                      /> */}
                      <div className="rulesAccept">
                        {/* <input
                      className="rulesAccept__checkbox"
                      type="checkbox"
                      id="confirmWithdraw"
                      checked={isRulesAccept}
                      onChange={() => setIsRulesAccept(!isRulesAccept)}
                    /> */}
                        <div
                          className={`rulesAccept__checkboxW ${
                            !isRulesAccept
                              ? "rulesAccept__checkbox_checked"
                              : ""
                          }`}
                          id="confirmWithdraw"
                          onClick={() => setIsRulesAccept(!isRulesAccept)}
                        />
                        <label htmlFor="confirmWithdraw"></label>
                        <span className="rulesAccept__text">
                          I have read and agree with &nbsp;
                          <span
                            className="rulesAccept__text-underline"
                            onClick={() =>
                              history.push("/termsOfWithdrowalPayment")
                            }
                          >
                            the terms of payment and withdrawal policy.
                          </span>
                        </span>
                      </div>
                      {width >= 1024 ? (
                        <div className="submitBtnW">
                          <button
                            type="button"
                            onClick={() =>
                              !moreThenFive
                                ? addWithrawMethod()
                                : onMoreThenFive()
                            }
                            className={` 
                          ${
                            isRulesAccept &&
                            isEnoughMoney &&
                            withdrawalIFSC?.length === 11 &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAccName &&
                            withdrawalAmount &&
                            bankName &&
                            !submitBtnDisabled
                              ? "submitValidBtn"
                              : "withdrawalNotValidBtn"
                          }`}
                            disabled={submitBtnDisabled}
                          >
                            {isRulesAccept &&
                            isEnoughMoney &&
                            withdrawalIFSC?.length === 11 &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAccName &&
                            withdrawalAmount &&
                            bankName ? (
                              <>
                                <span className="shadow"></span>
                                <span className="edge"></span>
                                <span className="front">Submit</span>
                              </>
                            ) : (
                              <div className="withdraw-submitNew__text">
                                Submit
                              </div>
                            )}
                          </button>
                        </div>
                      ) : (
                        <button
                          className={
                            !isRulesAccept &&
                            isEnoughMoney &&
                            withdrawalIFSC?.length === 11 &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAccName &&
                            withdrawalAmount &&
                            bankName &&
                            !submitBtnDisabled
                              ? "withdraw-submit"
                              : "withdrawalNotValidBtn"
                          }
                          onClick={() =>
                            !moreThenFive
                              ? addWithrawMethod()
                              : onMoreThenFive()
                          }
                          type="button"
                          // disabled={submitBtnDisabled}
                        >
                          <div className="withdraw-submit__text">Submit</div>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
          {/*{width < 1024 && <FooterDesktop />}*/}
        </IonContent>
      ) : (
        <div className="ChangePassword" fullscreen>
          <form action="">
            <div className="withdraw-screen">
              <div className="withdraw-desktop">
                <div className="withdraw-desktop__leftpage">
                  <div>
                    <h2 className="page-title">Withdraw funds</h2>
                    <div className="page-subtitle notes">
                      <p>
                        1. This form is for withdrawing the amount from the main
                        wallet only.
                      </p>
                      <p>
                        2. The bonus wallet amount cannot be withdrawn by this
                        form.
                      </p>
                      <p>
                        3. Do not put Withdraw request without betting with
                        deposit amount. Such activity may be identified as
                        Suspicious
                      </p>
                      <p>
                        4. If multiple users are using same withdraw account
                        then all the linked users will be blocked.
                      </p>
                      <p>5. Paytm account numbers always start with 91</p>
                    </div>
                  </div>
                  <div
                    className="page-help"
                    onClick={() => setOpenPopUpHelp(true)}
                  >
                    <span className="page-help__text">Help</span>
                    <img
                      loading={"lazy"}
                      className="page-help__img"
                      src={QuestionImg}
                      alt=""
                    />
                  </div>
                </div>
                <div className="withdraw-desktop__rightpage">
                  <div className="page-subtitle subtitle-hidden">
                    Please fill in all required fields
                  </div>
                  <div className="buttons-flex">
                    {width >= 1024 ? (
                      <button
                        type="button"
                        onClick={() => {
                          setInputesCustom(true);
                          setInputesFromHistory(false);
                          setWithdrawAccount();
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge refreshBtn"></span>
                        <span className="front refreshBtn">
                          Use New Account
                        </span>
                      </button>
                    ) : (
                      <div
                        className="withdraw-account__newitem "
                        onClick={() => {
                          setInputesCustom(true);
                          setInputesFromHistory(false);
                          setWithdrawAccount();
                        }}
                      >
                        <div className="withdraw-account__newitem-title">
                          Use New Account
                        </div>
                      </div>
                    )}
                    {width >= 1024 ? (
                      <button
                        type="button"
                        onClick={() => {
                          setInputesCustom(false);
                          setInputesFromHistory(true);
                          codeAcc();
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge refreshBtn"></span>
                        <span className="front refreshBtn">
                          Use Previous Account
                        </span>
                      </button>
                    ) : (
                      <div
                        className="withdraw-account__newitem"
                        onClick={() => {
                          setInputesCustom(false);
                          setInputesFromHistory(true);
                          codeAcc();
                        }}
                      >
                        <div className="withdraw-account__newitem-title">
                          Use Previous Account
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="withdraw-account">
                    <div className="withdraw-account__flex">
                      {inputesFromHistory && prevAccounts?.length > 0
                        ? prevAccounts.map((item) => {
                            return (
                              <label
                                htmlFor={item._id}
                                className={
                                  withdrawAccount === item._id
                                    ? "withdraw-account__item-checked"
                                    : "withdraw-account__item"
                                }
                                onClick={() => {
                                  setWithdrawAccount(item._id);
                                  setInputesFromHistory(true);
                                  setInputesCustom(false);
                                }}
                              >
                                <div
                                  className="withdraw-account__item-content"
                                  onClick={() => setItemIFSC(item)}
                                >
                                  <div>
                                    <div className="withdraw-account__item-content__account">
                                      {item.name?.length > 15
                                        ? item.name.slice(0, 15) + "..."
                                        : item.name}
                                    </div>
                                    <div className="withdraw-account__item-content__accountNo">
                                      {item.accnumber}
                                    </div>
                                    <div className="withdraw-account__item-content__IFSC">
                                      {item.bankName}
                                      <input
                                        className="check-account"
                                        type="radio"
                                        name="choosenaccount"
                                        checked={withdrawAccount === item._id}
                                        id={item._id}
                                      />
                                      <img
                                        loading={"lazy"}
                                        className="delete-account"
                                        src={deleteButton}
                                        alt=""
                                        onClick={() =>
                                          withdrawAccount === item._id &&
                                          setOpenPopUpDelete(true)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </label>
                            );
                          })
                        : null}
                    </div>
                  </div>

                  {/* PREVIOUS ACCOUNTS */}

                  {inputesFromHistory && itemIFSC && prevAccounts && (
                    <div className="withdraw-desktop__rightpage-inputcontent">
                      <div className="withdraw-input-content ">
                        <div
                          className={`page-subtitle ${
                            !withdrawalAmount && withdrawalAmountCheck === false
                              ? "withdraw__empty-input"
                              : ""
                          }`}
                        >
                          <span>Amount</span>
                          {!withdrawalAmount &&
                          withdrawalAmountCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <input
                          id="Amount"
                          className="withdraw-textarea"
                          type="text"
                          placeholder="Enter Amount"
                          value={withdrawalAmount}
                          onChange={(e) => onValidationAmount(e.target.value)}
                        />
                        <span className="withdraw-textarea__currency">INR</span>
                        <span className="withdraw-textarea__currencyy">₹</span>
                        <div className="withdraw-amaount-buttons">
                          {width >= 1024 ? (
                            <>
                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 1000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 1000
                                    );
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+1,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 5000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 5000
                                    );
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+5,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 10000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 10000
                                    );
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">
                                  +10,000
                                </span>
                              </button>
                            </>
                          ) : (
                            <>
                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 1000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 1000
                                    );
                                  }
                                }}
                              >
                                +1,000
                              </div>

                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 5000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 5000
                                    );
                                  }
                                }}
                              >
                                +5,000
                              </div>
                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 10000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 10000
                                    );
                                  }
                                }}
                              >
                                +10,000
                              </div>
                            </>
                          )}
                        </div>
                        <div className="validation-message">{message}</div>
                      </div>
                      <div className="withdraw-input-content">
                        <div className="page-subtitle">IFSC Code</div>
                        <input
                          className="withdraw-textarea uppercaseIFSC"
                          type="text"
                          // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                          onChange={(e) =>
                            onValidationIFSC(e.target.value.toUpperCase())
                          }
                          // defaultValue={itemIFSC?.IFSCCode}
                          disabled={true}
                          value={itemIFSC?.ifsc}
                        />
                        <div className="validation-message">{digits}</div>
                        {infoIFSC ? (
                          <div className="withdraw-textarea__description">
                            {infoIFSC?.BANK}, {infoIFSC?.BRANCH},{" "}
                            {infoIFSC?.CITY}
                          </div>
                        ) : null}
                      </div>
                      <div className="withdraw-input-content account-number-input-wrapper">
                        <div className="page-subtitle">Account number</div>
                        <input
                          id="account"
                          className={`withdraw-textarea ${
                            !showNumber && "hidden-accnumber"
                          }`}
                          type={width < 1024 ? "tel" : "text"}
                          placeholder="Enter Accout Number"
                          disabled={true}
                          onChange={(e) => onAccNumber(e.target.value)}
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 18))
                          }
                          value={
                            itemIFSC?.accnumber
                              ? itemIFSC?.accnumber
                              : withdrawalAccNumber
                          }
                        />
                        <img
                          className="eyeIconWithdrawal"
                          src={showNumber ? eye : eyeOff}
                          alt="eyeIcon"
                          onClick={() => setShowNumber(!showNumber)}
                        />
                        <div className="validation-message">{accNoEroor}</div>
                      </div>
                      <div className="withdraw-input-content">
                        <div className="page-subtitle">
                          Confirm account number
                        </div>
                        <input
                          className={`withdraw-textarea ${
                            !showConfirmNo && "hidden-accnumber"
                          }`}
                          type={width < 1024 ? "tel" : "text"}
                          placeholder="Enter Accout Number"
                          // disabled={true}
                          onChange={(e) =>
                            onValidationAccnumber(e.target.value)
                          }
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 18))
                          }
                          value={
                            itemIFSC?.accnumber
                              ? itemIFSC?.accnumber
                              : confirmAccNumber
                          }
                        />
                        <img
                          className="eyeIconWithdrawal"
                          src={showConfirmNo ? eye : eyeOff}
                          alt="eyeIcon"
                          onClick={() => setShowConfirmNo(!showConfirmNo)}
                        />
                        <div className="validation-message">
                          {accountNumber}
                        </div>
                      </div>
                      <div className="withdraw-input-content">
                        <div className="page-subtitle">Account name</div>
                        <input
                          className="withdraw-textarea"
                          type="text"
                          placeholder="Enter Accout Name"
                          onChange={(e) => onAccName(e.target.value)}
                          disabled={true}
                          value={
                            itemIFSC?.name ? itemIFSC?.name : withdrawalAccName
                          }
                          maxLength={32}
                        />
                        <div className="validation-message">{nameMessage}</div>
                      </div>
                      <div className="withdraw-input-content">
                        <div className="page-subtitle">Bank name</div>
                        <input
                          className="withdraw-textarea"
                          type="text"
                          placeholder="Enter Bank Name"
                          onChange={(e) => onBankName(e.target.value)}
                          disabled={true}
                          value={
                            itemIFSC?.bankName
                              ? itemIFSC?.bankName
                              : withdrawalAccName
                          }
                          maxLength={32}
                        />
                        <div className="validation-message">{nameMessage}</div>
                      </div>
                      {/* <Verification
                        submit={() => setOtpVerif(true)}
                        shake={shake}
                        home={false}
                      /> */}
                      <div
                        className={`rulesAccept ${
                          !isRulesAccept && isRulesAcceptCheck === false
                            ? "deposit__empty-input"
                            : ""
                        }`}
                      >
                        {!isRulesAccept && isRulesAcceptCheck === false ? (
                          <span className={`input-error-message`}>
                            Please accept the rules.
                          </span>
                        ) : (
                          ""
                        )}
                        <div
                          className={`rulesAccept__checkboxW ${
                            !isRulesAccept
                              ? "rulesAccept__checkbox_checked"
                              : ""
                          }`}
                          id="confirmWithdraw"
                          onClick={() => setIsRulesAccept(!isRulesAccept)}
                        />
                        <label htmlFor="confirmWithdraw"></label>
                        <span className="rulesAccept__text">
                          I have read and agree with &nbsp;
                          <span
                            className="rulesAccept__text-underline"
                            onClick={() =>
                              history.push("/termsOfWithdrowalPayment")
                            }
                          >
                            the terms of payment and withdrawal policy.
                          </span>
                        </span>
                      </div>
                      {width >= 1024 ? (
                        <div className="submitBtnW">
                          <button
                            type="button"
                            onClick={
                              !isRulesAccept &&
                              isEnoughMoney &&
                              !digits &&
                              isNamesMatch &&
                              withdrawalAmount
                                ? withrawRequest
                                : fieldCheck
                            }
                            className={` 
                        ${
                          !isRulesAccept &&
                          isEnoughMoney &&
                          !digits &&
                          isNamesMatch &&
                          withdrawalAmount 
                            ? "submitValidBtn"
                            : "withdrawalNotValidBtn"
                        }`}
                            disabled={submitBtnDisabled}
                          >
                            {!isRulesAccept &&
                            isEnoughMoney &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAmount ? (
                              <>
                                <span className="shadow"></span>
                                <span className="edge"></span>
                                <span className="front">Submit</span>
                              </>
                            ) : (
                              <div className="withdraw-submitNew__text">
                                Submit
                              </div>
                            )}
                          </button>
                        </div>
                      ) : (
                        <button
                          className={
                            isRulesAccept &&
                            isEnoughMoney &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAmount &&
                            !submitBtnDisabled
                              ? "withdraw-submit"
                              : "withdrawalNotValidBtn"
                          }
                          onClick={() =>
                            !moreThenFive ? withrawRequest() : onMoreThenFive()
                          }
                          type="button"
                          disabled={submitBtnDisabled}
                        >
                          <div className="withdraw-submit__text">Submit</div>
                        </button>
                      )}
                    </div>
                  )}

                  {/* CREATE NEW ACCOUNT INPUTS */}
                  {inputesCustom && (
                    <div className="withdraw-desktop__rightpage-inputcontent">
                      <div className="withdraw-input-content">
                        <div
                          className={`page-subtitle ${
                            !withdrawalAmount && withdrawalAmountCheck === false
                              ? "withdraw__empty-input"
                              : ""
                          }`}
                        >
                          <span>Amount</span>
                          {!withdrawalAmount &&
                          withdrawalAmountCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <input
                          id="Amount"
                          className="withdraw-textarea"
                          type="text"
                          placeholder="Enter Amount"
                          value={withdrawalAmount}
                          onChange={(e) => onValidationAmount(e.target.value)}
                        />
                        <span className="withdraw-textarea__currency">INR</span>
                        <span className="withdraw-textarea__currencyy">₹</span>
                        <div className="withdraw-amaount-buttons">
                          {width >= 1024 ? (
                            <>
                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 1000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 1000
                                    );
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+1,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 5000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 5000
                                    );
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+5,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 10000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 10000
                                    );
                                  }
                                }}
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">
                                  +10,000
                                </span>
                              </button>
                            </>
                          ) : (
                            <>
                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 1000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 1000
                                    );
                                  }
                                }}
                              >
                                +1,000
                              </div>

                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 5000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 5000
                                    );
                                  }
                                }}
                              >
                                +5,000
                              </div>
                              <div
                                className="withdraw-amaount-buttons__value"
                                onClick={() => {
                                  if (
                                    balance?.balance <
                                    +withdrawalAmount + 10000
                                  ) {
                                    messageNoBalance();
                                  } else {
                                    setMessage("");
                                    setIsEnoughMoney(true);
                                    setwithdrowalAmount(
                                      +withdrawalAmount + 10000
                                    );
                                  }
                                }}
                              >
                                +10,000
                              </div>
                            </>
                          )}
                        </div>
                        <div className="validation-message">{message}</div>
                      </div>
                      <div className="withdraw-input-content">
                        <div
                          className={`page-subtitle ${
                            !withdrawalIFSC && withdrawalIFSCCheck === false
                              ? "withdraw__empty-input"
                              : ""
                          }`}
                        >
                          <span>IFSC Code</span>
                          {!withdrawalIFSC && withdrawalIFSCCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <input
                          className="withdraw-textarea uppercaseIFSC"
                          type="text"
                          placeholder="eg. IFSC0000001"
                          // onReset={() => setInputedIFSC()}
                          // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                          onChange={(e) =>
                            onNewValidationIFSC(e.target.value.toUpperCase())
                          }
                          // defaultValue={itemIFSC?.IFSCCode}
                          // value={itemIFSC?.IFSCCode ? itemIFSC?.IFSCCode : inputedIFSC}
                        />
                        <div className="validation-message">{digits}</div>
                        {infoIFSC ? (
                          <div className="withdraw-textarea__description">
                            {infoIFSC?.BANK}, {infoIFSC?.BRANCH},{" "}
                            {infoIFSC?.CITY}
                          </div>
                        ) : null}
                      </div>
                      <div className="withdraw-input-content account-number-input-wrapper">
                        <div
                          className={`page-subtitle ${
                            !withdrawalAccNumber &&
                            withdrawalAccNumberCheck === false
                              ? "withdraw__empty-input"
                              : ""
                          }`}
                        >
                          <span>Account number</span>
                          {!withdrawalAccNumber &&
                          withdrawalAccNumberCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <input
                          id="account"
                          className={`withdraw-textarea ${
                            !showNumber && "hidden-accnumber"
                          }`}
                          type={width < 1024 ? "tel" : "text"}
                          placeholder="Enter Accout Number"
                          onChange={(e) => onAccNumber(e.target.value)}
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 18))
                          }
                          value={withdrawalAccNumber}

                          // defaultValue={itemIFSC?.accountNo}
                        />
                        <img
                          className="eyeIconWithdrawal"
                          src={showNumber ? eye : eyeOff}
                          alt="eyeIcon"
                          onClick={() => setShowNumber(!showNumber)}
                        />
                        <div className="validation-message">{accNoEroor}</div>
                      </div>
                      <div className="withdraw-input-content ">
                        <div
                          className={`page-subtitle ${
                            confirmAccNumber?.length < 1 &&
                            confirmAccNumberCheck === false
                              ? "withdraw__empty-input"
                              : ""
                          }`}
                        >
                          <span>Confirm account number</span>
                          {confirmAccNumber?.length < 1 &&
                          confirmAccNumberCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <input
                          className={`withdraw-textarea ${
                            !showConfirmNo && "hidden-accnumber"
                          }`}
                          type={width < 1024 ? "tel" : "text"}
                          placeholder="Enter Accout Number"
                          // disabled={!confirmAccNumber?.length && !withdrawalAccNumber}
                          onChange={(e) =>
                            onValidationAccnumber(e.target.value)
                          }
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 18))
                          }
                          value={confirmAccNumber}
                          // defaultValue={itemIFSC?.accountNo}
                        />
                        <img
                          className="eyeIconWithdrawal"
                          src={showConfirmNo ? eye : eyeOff}
                          alt="eyeIcon"
                          onClick={() => setShowConfirmNo(!showConfirmNo)}
                        />
                        <div className="validation-message">
                          {accountNumber}
                        </div>
                      </div>
                      <div className="withdraw-input-content">
                        <div
                          className={`page-subtitle ${
                            !withdrawalAccName &&
                            withdrawalAccNameCheck === false
                              ? "withdraw__empty-input"
                              : ""
                          }`}
                        >
                          <span>Account name</span>
                          {!withdrawalAccName &&
                          withdrawalAccNameCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <input
                          className="withdraw-textarea"
                          type="text"
                          placeholder="Enter Accout Name"
                          onChange={(e) => onAccName(e.target.value)}
                          value={withdrawalAccName}
                          // defaultValue={itemIFSC?.accountName}
                          maxLength={32}
                        />
                        <div className="validation-message">{nameMessage}</div>
                      </div>
                      <div className="withdraw-input-content">
                        <div
                          className={`page-subtitle ${
                            !bankName && bankNameCheck === false
                              ? "withdraw__empty-input"
                              : ""
                          }`}
                        >
                          <span>Bank name</span>
                          {!bankName && bankNameCheck === false ? (
                            <span className={`input-error-message`}>
                              Please fill required fields
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <input
                          className="withdraw-textarea"
                          type="text"
                          placeholder="Enter Bank Name"
                          onChange={(e) => onBankName(e.target.value)}
                          value={bankName}
                          // defaultValue={itemIFSC?.accountName}
                          maxLength={32}
                        />
                        <div className="validation-message">{bankMessage}</div>
                      </div>
                      {/* <Verification
                        submit={() => setOtpVerif(true)}
                        shake={shake}
                        home={false}
                      /> */}
                      <div
                        className={`rulesAccept ${
                          !isRulesAccept && isRulesAcceptCheck === false
                            ? "deposit__empty-input"
                            : ""
                        }`}
                      >
                        {!isRulesAccept && isRulesAcceptCheck === false ? (
                          <span className={`input-error-message`}>
                            Please accept the rules.
                          </span>
                        ) : (
                          ""
                        )}
                        {/* <input
                    className="rulesAccept__checkbox"
                    type="checkbox"
                    id="confirmWithdraw"
                    checked={isRulesAccept}
                    onChange={() => setIsRulesAccept(!isRulesAccept)}
                  /> */}
                        <div
                          className={`rulesAccept__checkboxW ${
                            !isRulesAccept
                              ? "rulesAccept__checkbox_checked"
                              : ""
                          }`}
                          id="confirmWithdraw"
                          onClick={() => setIsRulesAccept(!isRulesAccept)}
                        />
                        <label htmlFor="confirmWithdraw"></label>
                        <span className="rulesAccept__text">
                          I have read and agree with &nbsp;
                          <span
                            className="rulesAccept__text-underline"
                            onClick={() =>
                              history.push("/termsOfWithdrowalPayment")
                            }
                          >
                            the terms of payment and withdrawal policy.
                          </span>
                        </span>
                      </div>
                      {width >= 1024 ? (
                        <div className="submitBtnW">
                          <button
                            type="button"
                            onClick={
                              !isRulesAccept &&
                              isEnoughMoney &&
                              withdrawalIFSC?.length === 11 &&
                              !digits &&
                              withdrawalAccNumber &&
                              isNamesMatch &&
                              withdrawalAccName &&
                              withdrawalAmount &&
                              bankName
                                ? addWithrawMethod
                                : fieldCheck
                            }
                            className={` 
                        ${
                          !isRulesAccept &&
                          isEnoughMoney &&
                          withdrawalIFSC?.length === 11 &&
                          !digits &&
                          withdrawalAccNumber &&
                          isNamesMatch &&
                          withdrawalAccName &&
                          withdrawalAmount &&
                          bankName 
                            ? "submitValidBtn"
                            : "withdrawalNotValidBtn"
                        }`}
                            disabled={submitBtnDisabled}
                          >
                            {!isRulesAccept &&
                            isEnoughMoney &&
                            withdrawalIFSC?.length === 11 &&
                            !digits &&
                            withdrawalAccNumber &&
                            isNamesMatch &&
                            withdrawalAccName &&
                            withdrawalAmount &&
                            bankName ? (
                              <>
                                <span className="shadow"></span>
                                <span className="edge"></span>
                                <span className="front">Submit</span>
                              </>
                            ) : (
                              <div className="withdraw-submitNew__text">
                                Submit
                              </div>
                            )}
                          </button>
                        </div>
                      ) : (
                        <button
                          className={
                            isRulesAccept &&
                            isEnoughMoney &&
                            withdrawalIFSC?.length === 11 &&
                            !digits &&
                            isNamesMatch &&
                            withdrawalAccName &&
                            withdrawalAmount &&
                            bankName &&
                            !submitBtnDisabled
                              ? "withdraw-submit"
                              : "withdrawalNotValidBtn"
                          }
                          onClick={() =>
                            !moreThenFive ? addWithrawMethod() : onMoreThenFive()
                          }
                          type="button"
                          disabled={submitBtnDisabled}
                        >
                          <div className="withdraw-submit__text">Submit</div>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
          <FooterDesktop />
        </div>
      )}
      <PopUPDelete
        update={codeAcc}
        data={withdrawAccount}
        open={openPopUpDelete}
        onDidDismiss={() => setOpenPopUpDelete(false)}
      />
      <PopUPWithdrawalHelp
        // data={data}
        // content={editPoUP}
        open={openPopUpHelp}
        onDidDismiss={() => setOpenPopUpHelp(false)}
      />
      <SuccessPopUp
        open={showSuccessPopUP}
        onDidDismiss={() => setShowSuccessPopUP(false)}
        amount={withdrawalAmount}
      />
    </IonPage>
  );
};

export default connectWithdrawal()(Withdrawal);
