import "./LeftSideMenu.scss";
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonImg
} from "@ionic/react";
import { menuController } from '@ionic/core'
import { connectLeftSideMenu } from "./leftsidemenu.connect";
import leftSideMenuMapper from "../../helpers/getLeftSideMenuMapper.helper";
import { useHistory } from "react-router";
import SlotsgamesIcon from "../../assets/images/sideBarSlotsgames.svg";
import downloadApk from "../../assets/images/menu-icons/apk_icon.svg";
import instaButton from "../../assets/images/socials/instagram.svg";
import { CONFIG } from "../../config/api.config";
import ReactGA from "react-ga4";
import CasinoIcon from '../../assets/images/casino.png'


import CricketIcon from '../../assets/images/sideBarCricket.svg'
import FootballIcon from '../../assets/images/sideBarFooltball.svg'
import TennisIcon from '../../assets/images/sideBarTennis.svg'
import horseIcon from '../../assets/images/sidebar/horse-riding.svg';
import PoliticsIcon from '../../assets/images/sidebar/politics-icon.png';
import GreyhoundIcon from '../../assets/images/sidebar/greyhound-racing.svg';
import KabaddiIcon from '../../assets/images/sidebar/kabaddi-icon.png';
import BinaryIcon from '../../assets/images/sidebar/binary-icon.svg';
import IntCasinoIcon from '../../assets/images/sidebar/intcasino-icon.png';
import SportsbookIcon from '../../assets/images/sidebar/sportsbook-icon.svg';
import MartialArtsIcon from '../../assets/images/sidebar/martial-arts-icon.png';
import VolleyballIcon from '../../assets/images/sidebar/volleyball-icon.png';
import IceHockeyIcon from '../../assets/images/sidebar/ice-hockey.png';
import BasketballIcon from '../../assets/images/sidebar/basketball-icon.png';
import BaseballIcon from '../../assets/images/sidebar/baseball-icon.png';
import DartsIcon from '../../assets/images/sidebar/darts.png';
import FutsalIcon from '../../assets/images/sidebar/futsal-icon.png';
import TableTennisIcon from '../../assets/images/sidebar/tabletennis-icon.png';
import RugbyIcon from '../../assets/images/sidebar/rugby-icon.png';
import lIVECasinoIcon from '../../assets/images/sidebar/livecasino-icon.png';
import { config } from "process";


const LeftSideMenu = ({ menu, auth, user, version, actions }) => {
  const history = useHistory();
  const getMapMenu = (menuState) => {

    // const collectionPages = Object.assign([], menuState);
    // collectionPages.push({
    //   "type": "EVENT_TYPE",
    //   "name": "Aviator",
    //   "id": "67722-2",
    //   "sortOrder": -2,
    //   "redirect":"/casino_live/67722-2/FAWK",
    //   "isSvg": true,
    //   "children": [
    //     {
    //       "children": [],
    //       "name": "Aviator",
    //       "id": "67722-2",
    //       "type": "LIVE_CASINO",
    //       "providerId": 7,
    //     }
    //   ]
    // });

    // return collectionPages.map(menuItem => {
    return menuState.map(menuItem => {
      const menuHelperItem = Object.entries(leftSideMenuMapper).map(([key, value]) => value)
        .find(menuItemWrapper => menuItem.id == menuItemWrapper.id);
      return {
        ...menuItem,
        img: menuHelperItem ? menuHelperItem.img : "",
        isSvg: menuHelperItem ? menuHelperItem.isSvg : "",
        name: menuHelperItem ? menuHelperItem.name : menuItem.name
      };
    }).sort((a, b) => a.sortOrder - b.sortOrder);
  };

  const [menuItemsMap, setMenuItemsMap] = useState([]);


  useEffect(() => {
    // console.log("Menu heee haa: ", menu);

    setMenuItemsMap(getMapMenu(menu || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  const getMenuItems = () => {
    return menuItemsMap.map((value, index) => {
      return (
        <IonItem className="left-side-menu-item" key={index} onClick={(e) => {

          // ReactGA.event({
          //   label: value.name,
          //   category: "User-Click",
          //   action: value.name + " Left_Menu_Click"
          // })

          // console.log("Value Name: ", value);

          let page;
          if (value.id == "1444000") {
            // actions.setSlotCasinoPopUp(true)
            actions.openSlotCasinoPopUp();
            page = "/livecasino-lobby";
          }
          else if (value.id == "1444005") page = `/sportsbook/${value.id}`;
          else if (value.id == "1444001") page = `/exchange-games`;
          else if (value.redirect != undefined) {
            page = value.redirect;
          }
          else page = `/menu-event/${value.id}`;

          if (!page) {
            actions.setLoginNew(true);
            history.push({ pathname: "/default-page" });
          } else {
            history.push({
              pathname: page,
              state: { id: value.id }
            });
          }
        }}>
          {value.isSvg
            ? <IonImg className="left-side-menu-item__icon eventIcon svg" src={value?.img} />
            :
            <span className={`left-side-menu-item__icon eventIcon ${value?.name?.replace(/\s/g, "")}`}>{value.img}</span>
          }
          <IonLabel className="left-side-menu-item__label">{value?.name}</IonLabel>
        </IonItem>
      );
    });
  };

  return (
    <IonMenu side="start" id="LeftSide" menuId="LeftSide" contentId="LeftSide" type="push" swipeGesture={false}
             className="left-side-menu">
      <IonContent>
        <IonList className="left-side-menu-list">
          <IonItem className="left-side-menu-item" key="mymarket" onClick={(e) => {
            auth ? history.push("/my_markets/") : actions.setLoginNew(true); menuController.close(); actions.setLoginNavigationData({page: 'my_markets', ids: []});
            }}
          >
            <span className="left-side-menu-item__icon"></span>
            <IonLabel className="left-side-menu-item__label">My markets</IonLabel>
          </IonItem>
          {/* TODO: with mobile left side menu work here */}
          {/* {getMenuItems()} */}
          
          {(!user?.verifytoken || user?.details?.availableEventTypes.includes('4'))&&
            <IonItem
              className="left-side-menu-item"
              key="cricket"
              onClick={() => {
                actions.setIsNavigationID('4');
                actions.setIsNavigationName('Cricket');
                if(!history?.location?.pathname?.includes('default-page')) {
                  history.push("/default-page");
                }
                menuController.close();
              }}
            >
              <IonImg className="left-side-menu-item__icon eventIcon svg" src={CricketIcon} alt="" />
              <IonLabel className="left-side-menu-item__label">Cricket</IonLabel>
            </IonItem>
          }
          {(!user?.verifytoken || user?.details?.availableEventTypes.includes('2'))&&
            <IonItem
              className="left-side-menu-item"
              key="tennis"
              onClick={() => {
                actions.setIsNavigationID('2');
                actions.setIsNavigationName('Tennis');
                if(!history?.location?.pathname?.includes('default-page')) {
                  history.push("/default-page");
                }
                menuController.close();
              }}
            >
              <IonImg className="left-side-menu-item__icon eventIcon svg" src={TennisIcon} alt="" />
              <IonLabel className="left-side-menu-item__label">Tennis</IonLabel>
            </IonItem>
          }
          {(!user?.verifytoken || user?.details?.availableEventTypes.includes('1'))&&
            <IonItem
              className="left-side-menu-item"
              key="football"
              onClick={() => {
                actions.setIsNavigationID('1');
                actions.setIsNavigationName('Football');
                if(!history?.location?.pathname?.includes('default-page')) {
                  history.push("/default-page");
                }
                menuController.close();
              }}
            >
              <IonImg className="left-side-menu-item__icon eventIcon svg" src={FootballIcon} alt="" />
              <IonLabel className="left-side-menu-item__label">Soccer</IonLabel>
            </IonItem>
          }
          
          <IonItem
            className="left-side-menu-item"
            key="football_1"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Horse Racing'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={horseIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Horse Racing</IonLabel>
          </IonItem>
          {/* <IonItem
            className="left-side-menu-item"
            key="tennis_1"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Politics'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={PoliticsIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Politics</IonLabel>
          </IonItem> */}
          <IonItem
            className="left-side-menu-item"
            key="cricket_1"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Greyhound Racing'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={GreyhoundIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Greyhound Racing</IonLabel>
          </IonItem>
          {/* <IonItem
            className="left-side-menu-item"
            key="football_2"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Kabaddi'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={KabaddiIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Kabaddi</IonLabel>
          </IonItem> */}
          {/* <IonItem
            className="left-side-menu-item"
            key="tennis_2"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Binary'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={BinaryIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Binary</IonLabel>
          </IonItem> */}
          
          {(!user?.verifytoken || user?.details?.availableEventTypes.includes('c9'))&&
            <>
              <IonItem
                className="left-side-menu-item"
                key="Casino2"
                onClick={() => {
                  menuController.close();
                  if(user?.verifytoken) {
                    // history.push('/livecasino-lobby')
                    actions.setIsNavigationID('99999')
                    actions.setIsNavigationName('Casino');
                    actions.setCasinoType('Int Casino');
                    actions.openSlotCasinoPopUp();
                  }else {
                    actions.setLoginNew(true);
                    actions.setLoginNavigationData({ navigationId: '99999', navigationName: 'Casino', casinoType: 'Int Casino' });
                  }
                }}
              >
                <IonImg className="left-side-menu-item__icon eventIcon svg" src={IntCasinoIcon} alt="" />
                <IonLabel className="left-side-menu-item__label">Int Casino</IonLabel>
              </IonItem>
              
              <IonItem
                className="left-side-menu-item"
                key="Casino2"
                onClick={() => {
                  menuController.close();
                  if(user?.verifytoken) {
                    // history.push('/livecasino-lobby/LIVECASINO');
                    history.push('/livecasino-lobby');
                    actions.openSlotCasinoPopUp();
                  }else {
                    actions.setLoginNew(true);
                    actions.setLoginNavigationData({ page: 'livecasino-lobby', ids: [] });
                    // actions.setLoginNavigationData({ page: 'livecasino-lobby', ids: ['LIVECASINO'] });
                  }
                }}
              >
                <IonImg className="left-side-menu-item__icon eventIcon svg" src={CasinoIcon} alt="" />
                <IonLabel className="left-side-menu-item__label">Live Casino</IonLabel>
              </IonItem>
            </>
          }
          
          {/* <IonItem
            className="left-side-menu-item"
            key="cricket_2"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Mixed Martial Arts'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={MartialArtsIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Mixed Martal Arts</IonLabel>
          </IonItem> */}
          {/* <IonItem
            className="left-side-menu-item"
            key="tennis_3"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Volleyball'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={VolleyballIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Volleyball</IonLabel>
          </IonItem> */}
          {/* <IonItem
            className="left-side-menu-item"
            key="football_3"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Ice Hockey'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={IceHockeyIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Ice Hockey</IonLabel>
          </IonItem> */}
          {/* <IonItem
            className="left-side-menu-item"
            key="cricket_3"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Basketball'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={BasketballIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Basketball</IonLabel>
          </IonItem> */}
          {/* <IonItem
            className="left-side-menu-item"
            key="tennis_4"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Baseball'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={BaseballIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Baseball</IonLabel>
          </IonItem> */}
          {/* <IonItem
            className="left-side-menu-item"
            key="football_4"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Darts'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={DartsIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Darts</IonLabel>
          </IonItem> */}
          {/* <IonItem
            className="left-side-menu-item"
            key="cricket_4"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Futsal'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={FutsalIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Futsal</IonLabel>
          </IonItem> */}
          {/* <IonItem
            className="left-side-menu-item"
            key="tennis_5"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Table Tennis'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={TableTennisIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Table Tennis</IonLabel>
          </IonItem> */}
          {/* <IonItem
            className="left-side-menu-item"
            key="football_5"
            onClick={() => {
              menuController.close();
              actions.setComingSoonModal({isOpen: true, type: 'Rugby'});
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={RugbyIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Rugby</IonLabel>
          </IonItem> */}
          
          {(!user?.verifytoken || user?.details?.availableEventTypes.includes('c9'))&&
            <IonItem
              className="left-side-menu-item"
              key="Casino1"
              onClick={() => {
                menuController.close();

                if(user?.verifytoken) {
                  actions.setIsNavigationID('99999')
                  actions.setIsNavigationName('Casino');
                  actions.setCasinoType('Casino');
                  if(!history?.location?.pathname?.includes('default-page')) { history.push("/default-page");}
                }else {
                  actions.setLoginNew(true)
                  actions.setLoginNavigationData({ navigationId: '99999', navigationName: 'Casino', casinoType: 'Casino' });
                }
              }}
            >
              <IonImg className="left-side-menu-item__icon eventIcon svg" src={lIVECasinoIcon} alt="" />
              <IonLabel className="left-side-menu-item__label">Casino</IonLabel>
            </IonItem>
          }
          
          {/* <IonItem
            className="left-side-menu-item"
            key="Casino3"
            onClick={() => {
              menuController.close();
              if(user?.verifytoken) {
                history.push('/livecasino-lobby/LIVECASINO')
              }else {
                actions.setLoginNew(true)
              }
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={CasinoIcon} alt="" />
            <IonLabel className="left-side-menu-item__label">Live Casino</IonLabel>
          </IonItem> */}




          {/* <IonItem 
            className="left-side-menu-item" 
            onClick={() => {
              history.push("/slotsgames-lobby")
              actions.openSlotCasinoPopUp();
              // actions.setSlotCasinoPopUp(true)
            }}
          >
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={SlotsgamesIcon} />
            <IonLabel className="left-side-menu-item__label">Slots Games</IonLabel>
          </IonItem>
          <IonItem className="left-side-menu-item" onClick={() => {
            // ReactGA.event({
            //   label: "Download_App",
            //   category: "User-Click",
            //   action: "Left_Menu_Download_App"
            // });
            window.location.href = CONFIG.downloadAppLink
          }}>
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={downloadApk} />
            <IonLabel className="left-side-menu-item__label">Download APK</IonLabel>
          </IonItem> */}


        </IonList>

        {(CONFIG.isSocialMediaVisible)&&
          <div className="socials">
            {/* <IonLabel className="left-side-menu-item__label_Whatsapp">Get Instant ID on whatsapp</IonLabel> */}
            {(CONFIG.isWhatsappVisible)&&
              <a href={CONFIG.whatsappLink} target={"_blank"}>
                <div  className="whatsapp-button" />
              </a>
            }
            {/*<a href={CONFIG.telegramSupportLink} target={"_blank"}>*/}
            {/*  <div  className="telegram-support-button" />*/}
            {/*</a>*/}
            {(CONFIG.isInstaVisible)&&
              <a href={`https://instagram.com/${CONFIG.instagram}`} target={"_blank"}>
                <div   className="instagram-button"  />
              </a>
            }
            {(CONFIG.isTelegramVisible)&&
              <a href={`https://telegram.me/${CONFIG.telegram}`} target={"_blank"}>
                <div  className="telegram-button" />
              </a>
            }
            {/* <a href={CONFIG.whatsappSupportLink} target={"_blank"}>
              <div  className="whatsapp-care-button" />
            </a> */}

          </div>
        }


      </IonContent>
    </IonMenu>
  );
};

export default connectLeftSideMenu()(LeftSideMenu);
