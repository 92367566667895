import React, { useEffect, useRef, useState } from 'react'
import './ToolbarDesktop.scss'
import { IonInput } from '@ionic/react'
import { connectToolbarDesktop } from './ToolbarDesktop.connect'
import { useHistory } from 'react-router'
import login from '../../assets/images/login.svg'
import sign from '../../assets/images/sign.svg'
import SearchIconDesk from '../../assets/images/searchIconDesk.svg'
import Poligon from '../../assets/images/poligonIcon.svg'
import TimeSettingsDesktop from './TimeSettingsDesktop/TimeSettingsDesktop'
import { useSelector  } from 'react-redux'
import SearchDesktop from '../SearchDesktop/SearchDesktop'
import { showDeposit } from '../../helpers/showDeposit.helper'
import NotificationPopup from '../NotificationPopup/NotificationPopup'
import { openSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice'
import { useDispatch } from 'react-redux'
import { setRuleRegulationPopUp } from '../../store/ruleRegulationPopup/ruleRegulationPopup.slice'

const ToolbarDesktop = ({
  messages,
  notifications,
  user,
  contentScrolled = false,
  setStyle = () => null,
  actions,
  search = false,
  noSearch = true,
  menu,
  auth,
  B2C,
  animatedText,
  searchValue,
  isNavigationName,
}) => {
  let lastLogin = new Date(user?.lastLoginTime)

  const getDateTime = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
  }

  const history = useHistory()
  const dropdownRef = useRef(null)
  const dispatch = useDispatch()

  const [menuItemsMap, setMenuItemsMap] = useState([])
  const [dropDownMenu, setDropDownMenu] = useState()
  // const [searchValue, setSearchValue] = useState('')
  const [activeSearch, setActiveSearch] = useState()
  // const [animatedText, setAnimatedText] = useState(false)
  const [navigationName, setNavigationName] = useState('')

  const isNavigationOpen = useSelector(
    (state) => state.navigationHeaderMenu.isOpen
  )

  // console.log('isNavigationName', isNavigationName);

  useEffect(() => {
    setMenuItemsMap(menu || []);
  }, [menu])

  useEffect(() => {
    if (history.location.pathname.indexOf('/exchange-games') !== -1 || history.location.pathname.indexOf('/casino_live') !== -1 || history.location.pathname.indexOf('/sportsbook') !== -1 || history.location.pathname.indexOf('/scorecard') !== -1 || history.location.pathname.indexOf('/menu-event') !== -1 ) {
      {
        // !searchValue &&
        actions.setIsAnimatedText(false)}
      actions.setSearchValues('')
      actions.setIsNavigationOpen(false)
      actions.setIsNavigationReset(true)
      setTimeout(() => {
        actions.setIsNavigationReset(false)
      }, 500)
    }
  }, [history.location.pathname])

  const refScroll = useRef(null)

  const onActiveSearch = () => {
    setActiveSearch(!activeSearch)
  }

  const onSearch = () => {
    if (history.location.pathname.indexOf('/searchDesktop') == -1) {
      history.push('/searchDesktop')
    }
  }

  const hableSearchChange = (value) => {
    actions.setSearchValues(value)
  }

  const hableSearchClear = () => {
    actions.setSearchValues('')
  }

  useEffect(() => {
    actions.setSearchValue(searchValue)
  }, [actions, searchValue])

  useEffect(() => {
    if (contentScrolled) {
      setStyle({
        transform: `translate3d(0px, -${refScroll.current.clientHeight}px, 0px)`,
        height: '50px',
      })
    } else {
      setStyle({
        transform: 'none',
      })
    }
  }, [contentScrolled, setStyle])

  // const renderSearch = () => {
  //   if (noSearch)
  //     return !search ? (
  //       <>
  //         <div className="serach-events__text"></div>
  //         <input
  //           className="searchInput"
  //           name='search'
  //           autoComplete='off'
  //           // placeholder={animatedText ? '' : 'Search Events'}
  //           onClick={() => {
  //             // history.push('/searchDesktop')
  //             onActiveSearch(!activeSearch)
  //             actions.setIsAnimatedText(!animatedText)
  //           }}
  //         />
  //       </>
  //     ) : (
  //       <>
  //         <div
  //           className={
  //             animatedText
  //               ? 'serach-events__text-active'
  //               : 'serach-events__text'
  //           }
  //         >
  //         </div>
  //         <IonInput
  //           value={searchValue}
  //           onIonChange={(e) => hableSearchChange(e.target.value)}
  //           className="searchInput"
  //           name='search'
  //           autoComplete='false'
  //           autoCorrect='false'
  //           placeholder={animatedText ? '' : 'Search Events'}
  //         />
  //       </>
  //     )
  // }

  const contentDateRef = useRef(null)
  const closeAcc = (e) => e?.path && !e?.path?.includes(contentDateRef.current) ? setDropDownMenu(false) : null

  useEffect(() => {
    window.addEventListener('click', closeAcc)
    return () => window.removeEventListener('click', closeAcc)
  },[])

  const renderSearch = () => {
    return (
      <div className="form-row">
				<input 
          style={{background: 'white', color: 'black', border: '1px solid #DDDDDD',
            borderRadius: '3px',
          }}
          type="text" 
          id="form-email" 
          className="form-textbox"
          value={searchValue}
          onChange={(e) => hableSearchChange(e.target.value)}
          required/>
				<label htmlFor="form-email" className="form-label">Search Events</label>
			</div>
    )
  }

  const onDropDownMenu = () => {
    setDropDownMenu(!dropDownMenu)
  }

  const renderMenuItem = (text, additionalClass = '', action = () => {}) => (
    <div className={additionalClass} onClick={action}>
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.89798 9.06113C4.88265 9.02033 4.87737 8.98564 4.88257 8.95758C4.88774 8.9297 4.86992 8.9066 4.82904 8.8888C4.78816 8.871 4.76776 8.85687 4.76776 8.84679C4.76776 8.83661 4.73847 8.82891 4.67974 8.82387C4.62103 8.81876 4.59173 8.81615 4.59173 8.81615H4.49229H4.40812H2.20403C1.86731 8.81615 1.57902 8.69636 1.33919 8.4564C1.09942 8.2166 0.979543 7.92839 0.979543 7.59172V2.20392C0.979543 1.8672 1.09939 1.57901 1.33919 1.33922C1.57902 1.09939 1.86731 0.979436 2.20403 0.979436H4.65298C4.71933 0.979436 4.77149 0.962977 4.80985 0.929739C4.84805 0.896581 4.87351 0.846804 4.88632 0.780487C4.89908 0.714171 4.90678 0.654288 4.90935 0.600705C4.91187 0.547175 4.91064 0.479518 4.90549 0.397897C4.90042 0.316301 4.89788 0.265291 4.89788 0.244785C4.89788 0.178469 4.87362 0.121186 4.82518 0.0726153C4.77669 0.0242587 4.71933 0 4.65301 0H2.20403C1.59695 0 1.07778 0.215567 0.646622 0.646676C0.215568 1.07778 0 1.59687 0 2.20401V7.59169C0 8.19878 0.215568 8.71799 0.646622 9.14894C1.07778 9.58026 1.59695 9.79583 2.20403 9.79583H4.65309C4.71943 9.79583 4.77162 9.77916 4.80996 9.74613C4.84815 9.71292 4.87362 9.6632 4.88643 9.5968C4.89919 9.53057 4.90688 9.47039 4.90946 9.41702C4.91198 9.36344 4.91074 9.29591 4.9056 9.21429C4.90048 9.13259 4.89798 9.08161 4.89798 9.06113Z"
          fill="#FCEDCA"
        />
        <path
          d="M11.8546 4.5535L7.69134 0.390142C7.59447 0.293322 7.47963 0.244751 7.34697 0.244751C7.21432 0.244751 7.09948 0.293322 7.0025 0.390142C6.90555 0.487097 6.85711 0.602011 6.85711 0.734589V2.93867H3.42856C3.29582 2.93867 3.18107 2.98725 3.08411 3.08407C2.98716 3.18102 2.93872 3.29585 2.93872 3.42851V6.36733C2.93872 6.49996 2.98716 6.6148 3.08411 6.7117C3.18117 6.80846 3.2959 6.85717 3.42856 6.85717H6.85714V9.06123C6.85714 9.19378 6.90557 9.30872 7.00253 9.40559C7.09948 9.50255 7.21431 9.55109 7.347 9.55109C7.47963 9.55109 7.59447 9.50255 7.69137 9.40559L11.8546 5.24242C11.9515 5.14539 12 5.03071 12 4.89803C12 4.7654 11.9515 4.65046 11.8546 4.5535Z"
          fill="#FCEDCA"
        />
      </svg>
      <span>{text}</span>
    </div>
  )

  // console.log("User Bolte: ", user);

  return (
    <>
      <NotificationPopup messages={messages} actions={actions} />
      <div ref={refScroll}>
        {messages && messages.length > 0 && notifications !== false ? (
          <div className="ion-toolbar__wrapper-desktop notification__desktop">
            <div className="notification__container">
              <div className="notification__container__marquee-desktop">
                {messages
                  .filter((e) => !e.Type || e.Type == 'T')
                  .map((message) => (
                  <div
                    key={message.id}
                    className="notification__container__message-desktop"
                  >
                    {message.text}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="headerDesktop" onClick={()=> {
        actions.setSearchValues('')
      }}>
        <div className="headerDesktop-content">
          <div
            className="headerDesktop-content__logo"
            onClick={() => {
              history.push('/default-page')
              // !searchValue && 
              actions.setIsAnimatedText(false)
              actions.setIsNavigationOpen(false)
              actions.setIsNavigationReset(true)
              hableSearchClear()
              setActiveSearch(false)
              setTimeout(() => {
                actions.setIsNavigationReset(false)
              }, 500)
              actions.setIsMoreDetailed(false)
              actions.setIsNavigationID('');
              actions.setIsNavigationName('');
            }}
          >
            <div className="headerDesktop-content__logo-img" alt="" />
          </div>
          <TimeSettingsDesktop />
          <div
            className="headerDesktop-content__search"
            onClick={() => {
              // history.push('/searchDesktop')
              onActiveSearch(!activeSearch)
              actions.setIsAnimatedText(true)
            }}
          >
            <img className="search-event__img" src={SearchIconDesk} alt="" />
            {renderSearch()}
            {animatedText && <SearchDesktop/>}
          </div>
          {auth && (
            <>
              <div className="headerDesktop-content__loged">
                <div className="headerDesktop-content__loged-loginName">
                  Logged in as{' '}
                  {(user && user?.details) && user?.details?.username}
                    
                    {/* // (user
                    //   ?.details?.fullname.includes('.')? 
                    //   user?.details?.fullname.split('.')[1]
                    //   : 
                    //   user?.details?.fullname
                    // )} */}
                </div>
                <div className="headerDesktop-content__lastloged">
                  Last logged in:{' '}N/A
                  {/* {user && user.lastLoginTime && getDateTime(lastLogin)} */}
                </div>
              </div>
              {B2C && showDeposit() && (
              <>
                <div
                  className="headerDesktop-content__button"
                  onClick={() => {
                    history.push('/deposit')
                    actions.setIsNavigationReset(true)
                    setTimeout(() => {
                      actions.setIsNavigationReset(false)
                    }, 500)
                  }}
                >
                  <div>Deposit</div>
                </div>
                <div
                  className="headerDesktop-content__button"
                  onClick={() => {
                    history.push('/withdrawal')
                    actions.setIsNavigationReset(true)
                    setTimeout(() => {
                      actions.setIsNavigationReset(false)
                    }, 500)
                  }}
                >
                  <div>Withdraw</div>
                </div>
              </>
              )}
            </>
          )}
          <div className="headerDesktop-content__account" ref={contentDateRef}>
            {auth ? (
              <>
                <div className="text" onClick={() => {
                  onDropDownMenu(!dropDownMenu)
                  }}
                  >
                  <svg
                    className="text-img"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.73033 5.48626L7.90308 4.83938C7.91358 4.73962 7.92258 4.62301 7.92258 4.49963C7.92258 4.37625 7.91395 4.25964 7.90308 4.15988L8.73108 3.51264C8.88484 3.39114 8.92683 3.17551 8.82896 2.99589L7.9687 1.5075C7.87682 1.33951 7.67545 1.25325 7.47108 1.32862L6.49495 1.72049C6.30783 1.58549 6.1117 1.4715 5.90883 1.38038L5.76033 0.345006C5.73595 0.1485 5.56495 0 5.36283 0H3.63784C3.43571 0 3.2651 0.1485 3.24109 0.342L3.09222 1.38113C2.89571 1.46964 2.7026 1.58214 2.50722 1.72125L1.52847 1.32826C1.34507 1.25738 1.1257 1.33687 1.03457 1.50412L0.173211 2.99438C0.0715922 3.16614 0.113586 3.38926 0.270717 3.51376L1.09796 4.16064C1.08483 4.28701 1.07847 4.39689 1.07847 4.50002C1.07847 4.60315 1.08485 4.71301 1.09796 4.83977L0.269961 5.48701C0.116205 5.60888 0.0745805 5.82451 0.172455 6.00376L1.03271 7.49213C1.12459 7.65976 1.32409 7.74675 1.53033 7.67101L2.50646 7.27914C2.69321 7.41377 2.88933 7.52776 3.09222 7.61926L3.24072 8.65426C3.26508 8.8515 3.43571 9 3.63821 9H5.3632C5.56533 9 5.73633 8.8515 5.76033 8.658L5.9092 7.61926C6.1057 7.53038 6.29845 7.41825 6.4942 7.27875L7.47295 7.67174C7.5202 7.69011 7.56933 7.6995 7.61995 7.6995C7.76545 7.6995 7.89932 7.61999 7.96682 7.49624L8.83082 5.99999C8.92683 5.82449 8.88482 5.60888 8.73033 5.48626ZM4.50033 5.99999C3.67308 5.99999 3.00034 5.32724 3.00034 4.5C3.00034 3.67276 3.67308 3.00001 4.50033 3.00001C5.32757 3.00001 6.00032 3.67276 6.00032 4.5C6.00032 5.32724 5.32757 5.99999 4.50033 5.99999Z"
                      fill="#FCEDCA"
                    />
                  </svg>
                  <span>Account</span>
                  <nav
                    ref={dropdownRef}
                    className={`menuAccount ${
                      dropDownMenu ? 'active' : 'inactive'
                    }`}
                  >
                    <div className="poligon">
                      <img src={Poligon} alt="" />
                    </div>
                    <ul className="menuList">
                      {B2C && showDeposit() && (
                        <li
                        onClick={() => {
                          history.push('/transactions')
                          actions.setIsNavigationReset(true)
                          setTimeout(() => {
                            actions.setIsNavigationReset(false)
                          }, 500)
                        }}
                      >
                        Transactions
                        </li>
                      )}
                      <li onClick={() => history.push('/my-open-bets')}>
                        My Bets
                      </li>
                      {/* <li onClick={() => history.push('/bonuses')}>Bonuses</li> */}
                      <li
                        onClick={() => history.push('/betting_profit_and_loss')}
                      >
                        Betting Profit and Loss
                      </li>
                      <li onClick={() => history.push('/account_statement')}>
                        Account Statement
                      </li>
                      {/* <li onClick={() => history.push('/transfer_statement')}>
                        Transfer Statement
                      </li> */}
                      <li onClick={() => {dispatch(setRuleRegulationPopUp(true));}}>
                        Rules & Regulations
                      </li>
                      <li onClick={() => history.push('/change_password')}>
                        Change Password
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="text">
                  {renderMenuItem('Logout', 'text', actions.requestLogout)}
                </div>
              </>
            ) : (
              <>
                <div className="text not-auth" onClick={() => {actions.setLoginNew(true);}}>
                  <div className="login-icon-desktop">
                    <img src={login} alt="login"/>
                  </div>
                  Log in
                </div>
                {B2C && (
                  <div  className="text not-auth" onClick={() => {
                    actions.setLoginNew(true)
                    actions.setTab(2)
                  }}>
                    <div className="signUp-icon-desktop">
                      {/* <img src={sign} alt="signUp"/> */}
                    </div>
                    Sign up
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      
      <div className="headerDeskNav">
        <div className="headerDeskNav-content">
          <div className="headerDeskNav-content__list">
            {(!user?.verifytoken || user?.details?.availableEventTypes.includes('4'))&&
              <div
                className="list-item"
                onClick={() => {
                  actions.setIsNavigationID('4');
                  actions.setIsNavigationName('Cricket');
                  if(!history?.location?.pathname?.includes('default-page')) {
                    history.push("/default-page");
                  }
                  // auth ?
                    // history.push('/sportsbook/1444005')
                  // : actions.setLoginNew(true)
                }}
              >
                Cricket
              </div>
            }
            {(!user?.verifytoken || user?.details?.availableEventTypes.includes('2'))&&
              <div
                className="list-item"
                onClick={() => {
                  actions.setIsNavigationID('2');
                  actions.setIsNavigationName('Tennis');
                  if(!history?.location?.pathname?.includes('default-page')) {
                    history.push("/default-page");
                  }
                  // auth ?
                    // history.push('/sportsbook/1444005')
                  // : actions.setLoginNew(true)
                }}
              >
                Tennis
              </div>
            }
            {(!user?.verifytoken || user?.details?.availableEventTypes.includes('1'))&&
              <div
                className="list-item"
                onClick={() => {
                  actions.setIsNavigationID('1');
                  actions.setIsNavigationName('Football');
                  if(!history?.location?.pathname?.includes('default-page')) {
                    history.push("/default-page");
                  }
                  // auth ?
                    // history.push('/sportsbook/1444005')
                  // : actions.setLoginNew(true)
                }}
              >
                Soccer
              </div>
            }
            <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Horse Racing'});
              }}
            >
              Horse Racing
            </div>
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Politics'});
              }}
            >
              Politics
            </div> */}
            <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Greyhound Racing'});
              }}
            >
              GreyHound Racing
            </div>
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Kabaddi'});
              }}
            >
              Kabaddi
            </div> */}
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Binary'});
              }}
            >
              Binary
            </div> */}
            
            {(!user?.verifytoken || user?.details?.availableEventTypes.includes('c9'))&&
              <>
                <div
                  className="list-item"
                  onClick={() => {
                    if(user?.verifytoken) {
                      actions.setIsNavigationID('99999')
                      actions.setIsNavigationName('Casino');
                      actions.setCasinoType('Int Casino');
                      history.push('/default-page');
                      dispatch(openSlotCasinoPopUp());
                    }else{
                      actions.setLoginNew(true);
                      actions.setLoginNavigationData({ navigationId: '99999', navigationName: 'Casino', casinoType: 'Int Casino' });
                    }
                  }}
                >
                  Int Casino
                </div>
                <div
                  className="list-item"
                  onClick={() => {
                    if(user?.verifytoken) {
                      history.push('/livecasino-lobby');
                      dispatch(openSlotCasinoPopUp());
                    }else {
                      actions.setLoginNew(true);
                      actions.setLoginNavigationData({ page: 'livecasino-lobby', ids: [] });
                    }
                  }}
                >
                  Live Casino
                </div>
              </>
            }

            
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Mixed Martial Arts'});
              }}
            >
              Mixed Martial Arts
            </div> */}
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Volleyball'});
              }}
            >
              Volleyball
            </div> */}
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Ice Hockey'});
              }}
            >
              Ice Hockey
            </div> */}
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Basketball'});
              }}
            >
              Basketball
            </div> */}
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Baseball'});
              }}
            >
              BaseBall
            </div> */}
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Darts'});
              }}
            >
              Darts
            </div> */}
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Futsal'});
              }}
            >
              Futsal
            </div> */}
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Table Tennis'});
              }}
            >
              Table Tennis
            </div> */}
            {/* <div
              className="list-item"
              onClick={() => {
                actions.setComingSoonModal({isOpen: true, type: 'Rugby'});
              }}
            >
              Rugby
            </div> */}
            
            {(!user?.verifytoken || user?.details?.availableEventTypes.includes('c9'))&&
              <div
                className="list-item"
                onClick={() => {
                  if(user?.verifytoken) {
                    actions.setIsNavigationID('99999')
                    actions.setIsNavigationName('Casino');
                    actions.setCasinoType('Casino');
                    history.push('/default-page');
                    dispatch(openSlotCasinoPopUp());
                  }else{
                    actions.setLoginNew(true);
                    actions.setLoginNavigationData({ navigationId: '99999', navigationName: 'Casino', casinoType: 'Casino' });
                  }
                }}
              >
                Casino
              </div>
            }


            {/* TODO: menu add */}
            {/* {menuItemsMap.map(
              (item) =>
                item.id !== '1444005' &&
                item.id !== '1444000' && (
                  <div key={item?.id + item?.name}
                    className="list-item"
                    onClick={() => {
                      {item.id !== '1444001' && item.name !== isNavigationName &&
                      actions.setIsNavigationOpen(true)
                      actions.setRenderIonContent(false)
                      // actions.setIsAnimatedMenu(true)
                      setTimeout(() => {
                        actions.setIsNavigationOpen(false)
                      }, 400)
                      setTimeout(() => {
                        actions.setIsAnimatedMenu(false)
                      }, 400)
                      actions.setIsNavigationID(item.id)
                      actions.setIsNavigationName(item.name)
                      actions.setIsMoreDetailed('')
                      actions.setIsDetailedGroup('')}
                      history.push(
                        item.id === '4339' || item.id === '7'
                          ? '/racing-sports-page'
                          : item.id === '1444001'
                          ? '/exchange-games'
                          : '/sports-page'
                      )
                      {
                        !searchValue &&
                        actions.setIsAnimatedText(false)}
                      // console.log("adada", isNavigationOpen);
                    }}
                  >
                    {item.name.replace('Exchange Game', 'Indian Card Games')}
                  </div>
                )
            )} */}
            {/* <div
              className="list-item"
              onClick={() => {
                dispatch(setSlotCasinoPopUp(true))
                history.push('/livecasino-lobby')
              }}
            >
              live casino
            </div> */}
            {/* <div 
              className="list-item"
              onClick={() => {
              dispatch(setSlotCasinoPopUp(true))
              // auth ? history.push('/slotsgames-lobby') : actions.setLoginNew(true)
              history.push('/slotsgames-lobby')
            }}>
              slots games
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default connectToolbarDesktop()(ToolbarDesktop)
