import { createSlice } from '@reduxjs/toolkit'

export const comingSoonModalSlice = createSlice({
    name: 'comingSoonModal',
    initialState: {
        isOpen: false,
        type: '',
    },
    reducers: {
        // setComingSoonModal: (state, action) => action.payload,
        setComingSoonModal: (state, action) => ({ ...state, isOpen: action.payload.isOpen, type: action.payload.type}),
        setCloseComingSoonModal: (state) => ({ ...state, isOpen: false }),
    },
})

export const {
    setComingSoonModal,
    setCloseComingSoonModal
} = comingSoonModalSlice.actions

export default comingSoonModalSlice.reducer
