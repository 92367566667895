import React, { useEffect, useRef, useState } from 'react';
import './CasinoProvider.scss';
import HeaderTabs from './ProviderTabs/HeaderTabs';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { CasinoData } from '../../constants/Casino/CasinoData/CasinoData';
import { openSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice';
import { setLoginNew } from '../../store/LoginNew/loginNew.slice';
import { CasinoProviderDATA } from '../../constants/Casino/CasinoProviderData';
import { BASE_DEV_URL } from '../../config/api.config';
import axios from 'axios';
import { setLoginNavigationData } from '../../store/loginNavigation/LoginNavigation.slice';
import SlotsCasinoPopUp from '../SlotsCasinoPopup';


const CasinoProvider = () => {
    const auth = useSelector(state => state.auth);
    const casinoType = useSelector((state) => state.casinoProvider.casinoType);
    const selectedProvider = useSelector((state) => state.casinoProvider.selectedProvider);
    const slotCasinoPopUp = useSelector((state) => state.slotCasinoPopUp);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const casinoRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(casinoRef?.current) {
            scrollFocus();
        }
    }, []);

    const scrollFocus = () => {
        setTimeout(() => {
            casinoRef.current.scrollIntoView({
                behavior: 'smooth',
            })
        }, 400)
    }

    // only for the first time
    useEffect(() => {
        if(casinoType == 'Int Casino' && selectedProvider) {
            const index = CasinoProviderDATA.findIndex(item => item.name === selectedProvider);
            setSelectedIndex(index);
        }
    }, [casinoType, selectedProvider]);

    useEffect(() => {
        if(casinoType == 'Int Casino' && selectedIndex < CasinoProviderDATA.length) {
            requestCasinoProvider(CasinoProviderDATA[selectedIndex]?.provider);
        }
    }, [casinoType, selectedIndex]);


    const requestCasinoProvider = async(provider) => {
        if(auth) {
            setLoading(true);
            const getRes = await axios.post(BASE_DEV_URL + `providerGames/`, {gametype: '', providers: provider}, {
                headers: { Authorization: `Bearer ${auth}`, }
            });
            if(getRes.status == 200 && getRes.data.success) {
                setData(getRes?.data?.data?.items || []);
            }
            setLoading(false);
        }else{
            dispatch(setLoginNew(true));
        }
    }


    const onSelectGame = (data) => {
        if(!data?.gameId) return;
        const id = data?.gameId;
        const tableId = data?.tableId || '';

        if(auth) {
            history.push(`/casino_live/${id}/${tableId}`);
            dispatch(openSlotCasinoPopUp());
        }else {
            dispatch(setLoginNew(true));
            dispatch(setLoginNavigationData({ page: 'casino_live', ids: [id, tableId] }));
        }
    }

    const onSelectProviderGame = (data) => {
        if(!data?.id) return;
        const id = data?.id;
        const tableId = data?.tableId || '';
        
        if(auth) {
            history.push(`/casino_live/${id}/${tableId}`);
            dispatch(openSlotCasinoPopUp());
        }else {
            dispatch(setLoginNew(true));
            dispatch(setLoginNavigationData({ page: 'casino_live', ids: [id, tableId] }));

        }
    }

    const CasinoTabs = [
        {
            name: 'LIVE CASINO',
        },
        {
            name: 'VIRTUAL CASINO',
        },
    ]

    return (
        <div ref={casinoRef} className='casino-provider-container'>
            <div className='casino-provider-header-list'>
                <HeaderTabs 
                    tabs={casinoType === 'Casino'? CasinoTabs : CasinoProviderDATA}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={(val) => {setSelectedIndex(val);}}
                />
            </div>

            {casinoType === 'Casino'&&
                <div className='casino-games-container'>
                    {(CasinoData[selectedIndex]?.length > 0)&&
                        CasinoData[selectedIndex].map((item) => {
                            return(
                                <div 
                                    className='casino-game-box' key={item?.id}
                                    onClick={() => {onSelectGame(item);}}
                                >
                                    <div className='casino-icon-box'>
                                        <img 
                                            src={item?.imageUrl}
                                            alt={item?.name}
                                        />
                                        <div className='casino-name'>PLAY NOW</div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            }

            {casinoType === 'Int Casino'&&
                <div className='casino-games-container'>
                    {(data?.length > 0)&&
                        data.map((item) => {
                            return(
                                <div 
                                    className='casino-game-box' key={item?.id}
                                    onClick={() => {onSelectProviderGame(item);}}
                                >
                                    <div className='casino-icon-box'>
                                        <img 
                                            src={item?.images[1]?.url}
                                            alt={item?.name}
                                        />
                                        <div className='casino-name'>PLAY NOW</div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            }

            {loading&&
                <div className='spinner-container'>
                    <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" style={{marginTop: '150px'}}>
                        <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
            }

            {slotCasinoPopUp?.isOpen && <SlotsCasinoPopUp/>}
        </div>
    )
}

export default CasinoProvider;