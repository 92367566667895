// import './pop-up.scss'
import React, { useState, useEffect, useRef } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { IonInput,  IonItem, IonList } from '@ionic/react'
import ReCAPTCHA from 'react-google-recaptcha'
import eye from '../../../assets/images/eye.svg'
import eyeOff from '../../../assets/images/eyeOff.svg'
import closeLogin from '../../../assets/images/closeLogin.svg'
import lotusLogo from '../../../assets/images/lotusLogo.png'
import { connectLoginPopUp } from '../loginPopUp.connect'
import UpdatePopUp from '../../../components/UpdatePopUp/index.js'
import { CONFIG } from "../../../config/api.config";
import GoogleIcon from './google-icon.svg'
import WhatsappIcon from './whatsup-icon1.svg'
import { Socket } from '../../../services/socket'
import { setUserDataSuccess } from '../../../store/user/user.slice'
import { updateToken } from '../../../store/auth/auth.thunks'

const LogInStep = ({
  open,
  onDidDismiss,
  recaptchaResponseProps,
  actions,
  B2C,
  changeTab,
  width,
  focus,
  setFocus,
  loginNavigation
}) => {
  const dispatch = useDispatch();
  const refRecaptcha = useRef(null)
  const history = useHistory()
  let [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const [passwordRules, setPasswordRules] = useState({
    length: false,
    specChar: false,
    capitalLetter: false,
    digit: false,
    password: '',
  })
  const [showPasswordRules, setShowPasswordRules] = useState(false)
  const [allRulesChecked, setAllRulesChecked] = useState(false)
  const [googleButtonWrapper, setGoogleButtonWrapper] = useState()
  const [googleLoginWrapper, setGoogleLoginWrapper] = useState()

  const getBalance = (memberCode) => {
    //TODO: change balance request
    
    
    // actions
    //   .requestBalance(memberCode)
    //   .then((res) => {
    //     if (B2C && res.data.result.availBalance < 100) {
    //       // history.push("/deposit")
    //     }
    //   })
    //   .catch((e) => console.log('balance error = ', e))
  }

  // useEffect(() => {
  //       setGoogleLoginWrapper(createGoogleWrapper());
  // }, [])

  

  useEffect(() => {
    const handleLoginuccess = async(...args) => {
      setLoading(false);

      // console.log("data: ", args[0]);

      if (args[0].success == false) {
        toastr.error('', args[0].message)
        if (refRecaptcha.current) refRecaptcha.current.reset()
        // window.location.reload();
        return;
      }
      let user = args[0].output;
      // console.log("User: ", user);
      if (user != null) {
        dispatch(setUserDataSuccess(user));

        localStorage.setItem('user_data', JSON.stringify(user));
        localStorage.setItem("B2C", user?.isB2C || false);
        localStorage.setItem("isLogin", true );

        if (!user?.verifytoken) throw new Error("Response has no token");
        dispatch(updateToken(user?.verifytoken));

        setTimeout(() => {
          handleLoginNavigation();
        }, 300);
      }

    };

    Socket.on("login-success", handleLoginuccess);

    return () => {
      Socket.off("login-success", handleLoginuccess);
    }
  }, [Socket]);


  useEffect(() => {
    if (
      !passwordRules.length ||
      !passwordRules.specChar ||
      !passwordRules.capitalLetter ||
      !passwordRules.digit
    ) {
      if (passwordRules.password) {
        setShowPasswordRules(true)
        setAllRulesChecked(false)
      }
    } else {
      setShowPasswordRules(false)
      setAllRulesChecked(true)
    }
  }, [passwordRules])

  const submit = async () => {
    // LOGIN API CHANGE
    //
    console.log("click submit")

    try {
      if (!username || !password || loading) return
      setLoading(true)
      // const recaptchaResponse =
      //   await recaptchaResponseProps.current.executeAsync()
      //   console.log("try new payload here", username, "pass", password)
      // console.log('recaptchaResponse11', recaptchaResponse)
      // // let newUserName = ''
      // let mockData = {
      //   "user_name" : 'lcdemo01',
      //   "password": 'Abcd12345',
      //   "grant_type": 'password'
      // }
      // let data = {
      //   "user_name" : username,
      //   "password": password,
      //   "grant_type": 'password'
      // }
      // newUserName = selectedType == 'mob' ? `91_${username}` : username
      // if (B2C) {
        

      const data = { 
        user:  {
          username: username,
          password: password,
          siteurl: CONFIG.siteurl,
          manager: ''
        }
      };

      Socket.emit("login", data);







      // Socket

      // console.log("Data: ", data);

      // setLoading(false);
      
      
      
      // await actions
      //     .requestAuth(data)
      //     // .requestAuth({
      //     //   username: newUserName,
      //     //   password,
      //     //   recaptchaResponse,
      //     //   answer: '',
      //     //   token: '',
      //     // })

      //     .then((res) => {
      //       console.log("then after request ")
      //       setLoading(false)
      //       // getBalance(res.data.result.memberCode)
      //       onDidDismiss()
      //       // window.location.reload()

      //     })
      // } else {
      //   await actions
      //     .requestAuth({
      //       username,
      //       password,
      //       recaptchaResponse,
      //       answer: '',
      //       token: '',
      //     })
      //     .then((res) => {
      //       setLoading(false)
      //     })
      // }
    } catch (e) {
      setLoading(false)
      console.log("in error")
      toastr.error('', `${e}`)
      if (refRecaptcha.current) refRecaptcha.current.reset()
    }
  }

  const loginWithDemoId = () => {
    setLoading(true);
    const data = { 
      user:  {
        username: 'demo',
        password: 'Test@123',
        siteurl: 'zolowin.com'
      }
    };

    Socket.emit("login", data);
  }


  const handleLoginNavigation = () => {
    const {navgationPage, idArray, navigationId, navigationName, casinoType, selectedCasinoProvider} = loginNavigation;

    if(!!navgationPage) {
      const fullPath = `/${navgationPage}/${idArray.join('/')}`;
      // console.log("Full Path: ", fullPath);
      history.push(fullPath);
      if(navgationPage.includes('livecasino-lobby') || navgationPage.includes('casino_live')
      ) {
        actions.openSlotCasinoPopUp();
      }
    }

    if(!!navigationId) {
      actions.setIsNavigationID(navigationId)
    }if(!!navigationName) {
      actions.setIsNavigationName(navigationName);
      if(navigationName.toLowerCase() == 'casino') {
        actions.openSlotCasinoPopUp();
      }
    }if(!!casinoType) {
      actions.setCasinoType(casinoType);
    }if(!!selectedCasinoProvider) {
      actions.setSelectedProvider(selectedCasinoProvider);
    }

    actions.clearNavigationData();
  }


  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      submit()
    }
  }

  const changeName = (e) => {
    const value = e?.target?.value
    //TODO: add validation rules

    // if (selectedType == 'mob') {
    //   if (value?.length <= 10) {
    //     e.target.value = value?.replace(/[^\d]/g, '')
    //     setUsername(e.target.value)
    //   } else {
    //     e.target.value = username
    //   }
    //   return
    // }
    // if (selectedType == 'user') {
    //     setUsername(value)
    //   return
    // }
    setUsername(value)
      return


    // if (e?.target?.value?.length <= 10) {
    //   setUsername(e.target.value?.replace(/\D/g, ''))
    // } else if (selectedType == 'mob') {
    //   if(e?.target?.value) e.target.value = username?.replace(/\D/g, '')
    // }
  }

  const changePass = (e) => {
    if (e?.target?.value?.length <= 16) {
      setPassword(e.target.value)
    } else {
      if(e?.target?.value) e.target.value = password
    }
  }

  // update pop-up
  const [isOpen, setIsOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [selectedType, selectType] = useState('mob')
  const [showSelection, setSelection] = useState(false)

  const showHide = () => {
    setShow(!show)
  }
  const onfocusInput = () => {
    setFocus(true)
  }
  const onBlurInput = () => {
    setFocus(false)
  }

  const handleSelect = (type) => {
    setSelection(false)
    selectType(type)
  }

  const createGoogleWrapper = () => {
    window.google.accounts.id.initialize({
      client_id: "454874373641-aaiuk6rbot84klbhbeduicgddbguvho5.apps.googleusercontent.com",
      callback: async (response) => {
        const token = response.credential;

        const recaptchaResponse = await recaptchaResponseProps.current.executeAsync();
        await actions
          .requestAuthGoogle({
            recaptchaResponse,
            token: token,
          })
          .then((response) => {
            window.srtmCommands.push({
              event: "track.user.login",
              payload: {
                action: "complete",
                userId: response.data.result.memberCode
              }
            });
            setLoading(false);
          });
        console.log(response);
      },
      auto_select: false,
      ux_mode: "popup",
    })


    const btn = document.createElement('div');
    btn.style.display = 'none';
    btn.classList.add('custom-google-button');
    document.body.appendChild(btn);

    window.google.accounts.id.renderButton(btn, {});

    const googleLoginWrapper = btn.querySelector("div[role=button]");
    setGoogleButtonWrapper(btn.querySelector("div[role=button]"))
    return {
      click: () => {
        googleLoginWrapper.click();
      }
    }
  }

  const glogin = async () => {
    googleButtonWrapper.click();
    googleLoginWrapper.click();
}

useEffect(() => {
  setUsername('')
}, [selectedType]);


  // close time dropdown after click somewhere
  const contentDateRef = useRef(null)
  const closeOptions = (e) =>
    !e.path?.includes(contentDateRef.current) ? setSelection(false) : ""
  const optionsRef = useRef();

  useEffect(() => {
    if (showSelection) {
      window.addEventListener("click", closeOptions)
      return () => window.removeEventListener("click", closeOptions)
    }
    return () => window.removeEventListener("click", closeOptions)
  }, [showSelection]);

  return width > 1024 ? (
    <>
      {
        <div className="loginPopUpContainer-login">
          {B2C ? (
            <IonList class="login-content__list">
              <input
                className="loginInput"
                formcontrolname="username"
                // onClick={onfocusInput}
                placeholder={
                  selectedType == 'mob' ? "10 Digit Phone Number" : 'ID'
                }
                name="username"
                type={selectedType == 'mob' ? 'tel' : 'text'}
                value={`${username?.replace(' ', '')}`}
                autoComplete="username"
                onChange={changeName}
                onIonChange={changeName}
                hidden
              />
              <input
                formcontrolname="password"
                onClick={onfocusInput}
                className="loginInput"
                placeholder="Password"
                onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
                name="password"
                type="password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => changePass(e.target.value)}
                hidden
              />
              <div className="b2c_login">
                <IonItem className="overflow-visible">
                  {/* {selectedType == 'mob' ? (
                    <div className="placeholder">+91</div>
                  ) : (
                    ''
                  )} */}
                  <input
                    formcontrolname="username"
                    className={`loginInput ${
                      selectedType == 'user' ? 'remove-pad-start' : ''
                    }`}
                    // onClick={onfocusInput}
                    placeholder={
                      // selectedType == 'mob' ? "10 Digit Phone Number" : 'ID'
                      "Enter Username"
                    }
                    name="username"
                    // type={selectedType == 'mob' ? 'tel' : 'text'}
                    autoComplete="username"
                    value={`${username?.replace(' ', '')}`}
                    onKeyDown={onKeyDown}
                    // onChange={changeName}
                    onChange={changeName}
                    onIonChange={changeName}
                  />
                  <div className={`login_icons`}>
                    {/* <div onClick={() => setSelection(true)} className={'icon'}>
                      <div className={`selected-icon ${selectedType}`}></div>
                      <div className="arrow-icon"></div>
                    </div> */}

                    <div
                      ref={optionsRef}
                      className={`option-menu ${
                        showSelection ? 'show' : 'hide'
                      }`}
                    >
                      <div
                        onClick={() => handleSelect('mob')}
                        className={`option-item ${
                          selectedType == 'mob' ? 'selected' : ''
                        }`}
                      >
                        <div className="mob-icon"></div>
                        <div className="title">Mobile Number</div>
                      </div>
                      <div
                        onClick={() => handleSelect('user')}
                        className={`option-item ${
                          selectedType == 'user' ? 'selected' : ''
                        }`}
                      >
                        <div className="user-icon"></div>
                        <div className="title">User ID</div>
                      </div>
                    </div>
                  </div>
                </IonItem>

                <IonItem>
                  <IonInput
                    className="loginInput remove-pad-start"
                    formcontrolname="password"
                    placeholder="Enter Password"
                    // onClick={onfocusInput}
                    name="password"
                    type={show ? 'text' : 'password'}
                    value={password}
                    autocomplete="current-password"
                    onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
                    id="current-password"
                    onKeyDown={onKeyDown}
                    onIonChange={changePass}
                    onChange={changePass}
                  />

                  {/*<img className='icons' src={show ? eyeOff : eye }  onClick={showHide} />*/}
                  <div
                    className={`${
                      show ? 'login_icons eyeOff' : 'login_icons eye'
                    } `}
                    onClick={showHide}
                  />
                </IonItem>
              </div>
            </IonList>
          ) : (
            <IonList class="login-content__list">
              <input
                className="loginInput"
                formcontrolname="username"
                placeholder="Login Name"
                // onClick={onfocusInput}
                name="username"
                type="username"
                value={`${username?.replace(' ', '')}`}
                autoComplete="Phone Number"
                onChange={changeName}
                onIonChange={changeName}
                hidden
              />
              <input
                className="loginInput"
                formcontrolname="password"
                placeholder="Password"
                onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
                // onClick={onfocusInput}
                name="password"
                type="password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => changePass(e.target.value)}
                hidden
              />
              <IonItem>
                <IonInput
                  className="loginInput"
                  formcontrolname="username"
                  placeholder="Login Name"
                  // onClick={onfocusInput}
                  name="username"
                  type="username"
                  autoComplete="username"
                  value={`${username?.replace(' ', '')}`}
                  onKeyDown={onKeyDown}
                  onChange={changeName}
                  onIonChange={changeName}
                />
              </IonItem>
              <IonItem>
                <IonInput
                  className="loginInput"
                  formcontrolname="password"
                  placeholder="Password"
                  // onClick={onfocusInput}
                  name="password"
                  onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  onKeyDown={onKeyDown}
                  onIonChange={changePass}
                />
              </IonItem>
            </IonList>
          )}
        </div>
      }
      {/* {B2C && (
        <div
          className="pop-up-content__buttons pop-up-content__buttons-new forgot"
          // onClick={() => actions.setTab(3)}
        >
          <span onClick={() => actions.setTab(3)}>
            Log In with OTP?
          </span>
        </div>
      )} */}
      <div className="pop-up-content__buttons">
        <div
          onClick={submit}
          className="pushable"
          disabled={!username || !password || loading}
        >
          <span className="shadow"></span>
          <span className="edge "></span>
          <span className="front refreshBtn login">Log in
          {loading && <span className='loadingLogIn'></span>}
          </span>
        </div>
      </div>
      <div className="pop-up-content__buttons" style={{marginTop: 10}}>
        <div
          onClick={()=> {loginWithDemoId();}}
          className="pushable"
        >
          <span className="shadow"></span>
          <span className="edge "></span>
          <span className="front refreshBtn login" style={{color: '#000'}}>Log in With Demo Id
          {loading && <span className='loadingLogIn'></span>}
          </span>
        </div>
      </div>

      <div className="signUpNew_info"  style={{marginTop: '8px'}}>
        <span className="signUpNew_info-text">Forgot Password? </span>&nbsp;
        <span onClick={() => actions.setTab(3)} className="signUpNew_info-link">Log in with OTP</span>
      </div>
      
      {/* <div className='registerWith-content'>
        <div className="register-chose">Or Log in with</div>
        <div className='registerWith-content-btns'>
          <div className="register-button" onClick={() => glogin()}>
            <img className="icon" src={GoogleIcon} alt="" />
            <span className="register-button-link">Google</span>
          </div>
          <div className="register-button" onClick={() =>
              // actions.setTab(2)
              // window.open('https://lotus365.authlink.me')
              window.location.href = `https://lotus365.authlink.me/?redirectUri=http://${window.location.host}`
            }
          >
              <img className="icon" src={WhatsappIcon} alt="" />
              <span className="register-button-link">Whatsapp</span>
          </div>
        </div>
      </div> */}
    </>
  ) : (
    <div className={`login-step-wrapper `}>
      <div className="pop-up-content_title" onClick={() => onDidDismiss(false)}>
        <div className="pop-up-content_title-logo">
        </div>
        <img src={closeLogin} />
      </div>
      <div className="login-step-content">
        <div className="login-step-content__title">Log in</div>
        {
          <div className="loginPopUpContainer-login">
            {B2C ? (
              <IonList class="login-content__list">
                <input
                  className="loginInput"
                  placeholder={selectedType == 'mob' ? "Phone Number" : 'ID'}
                  // onClick={onfocusInput}
                  name="username"
                  type={selectedType == 'mob' ? 'text' : 'text'}
                  value={`${username?.replace(' ', '')}`}
                  autoComplete="username"
                  // onChange={changeName}
                  onChange={(e) => changeName(e)}
                  onIonChange={changeName}
                  hidden
                />
                <input
                  className="loginInput"
                  formcontrolname="password"
                  placeholder="Password"
                  // onClick={onfocusInput}
                  onIonBlur={onBlurInput}
                  name="password"
                  onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => changePass(e.target.value)}
                  hidden
                />
                <div className="b2c_login">
                  <IonItem className="login overflow-visible">
                    {/* {selectedType == 'mob' ? (
                      <div className="placeholder">+91</div>
                    ) : (
                      ''
                    )} */}
                    <input
                      className={`loginInput ${
                        selectedType == 'user' ? 'remove-pad-start' : ''
                      }`}
                      formcontrolname="username"
                      placeholder={
                        // selectedType == 'mob' ? "Phone Number" : 'ID'
                        "Enter Username"
                      }
                      // onClick={onfocusInput}
                      onIonBlur={onBlurInput}
                      name="username"
                      type={selectedType == 'mob' ? 'text' : 'text'}
                      autoComplete="username"
                      value={`${username?.replace(' ', '')}`}
                      onKeyDown={onKeyDown}
                      // onChange={changeName}
                      onChange={(e) => changeName(e)}
                      onIonChange={changeName}
                    />
                    <div className={`login_icons`}>
                      <div
                        onClick={() => setSelection(true)}
                        className={'icon'}
                      >
                        <div className={`selected-icon ${selectedType}`}></div>
                        <div className="arrow-icon"></div>
                      </div>

                      <div
                        className={`option-menu ${
                          showSelection ? 'show' : 'hide'
                        }`}
                      >
                        <div
                          onClick={() => handleSelect('mob')}
                          className={`option-item ${
                            selectedType == 'mob' ? 'selected' : ''
                          }`}
                        >
                          <div className="mob-icon"></div>
                          <div className="title">Mobile Number</div>
                        </div>
                        <div
                          onClick={() => handleSelect('user')}
                          className={`option-item ${
                            selectedType == 'user' ? 'selected' : ''
                          }`}
                        >
                          <div className="user-icon"></div>
                          <div className="title">User ID</div>
                        </div>
                      </div>
                    </div>
                  </IonItem>

                  <IonItem className="login">
                    <IonInput
                      className="loginInput  remove-pad-start"
                      formcontrolname="password"
                      placeholder="Password"
                      // onClick={onfocusInput}
                      onIonBlur={onBlurInput}
                      onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
                      name="password"
                      type={show ? 'text' : 'password'}
                      value={password}
                      autoComplete="current-password"
                      onKeyDown={onKeyDown}
                      onIonChange={changePass}
                    />
                    {/*<img className='icons' src={show ? eyeOff : eye }  onClick={showHide} />*/}
                    <div
                      className={`${
                        show ? 'login_icons eyeOff' : 'login_icons eye'
                      } `}
                      onClick={showHide}
                    ></div>
                  </IonItem>
                </div>
              </IonList>
            ) : (
              <IonList class="login-content__list">
                <input
                  className="loginInput"
                  formcontrolname="username"
                  placeholder="Login Name"
                  // onClick={onfocusInput}
                  onBlur={onBlurInput}
                  name="username"
                  type={'text'}
                  value={`${username?.replace(' ', '')}`}
                  autoComplete="Phone Number"
                  onChange={changeName}
                  // onIonChange={changeName}
                  hidden
                />
                <input
                  className="loginInput"
                  formcontrolname="password"
                  placeholder="Password"
                  // onClick={onfocusInput}
                  onBlur={onBlurInput}
                  name="password"
                  onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => changePass(e.target.value)}
                  hidden
                />
                <IonItem className="login">
                  <IonInput
                    className="loginInput"
                    formcontrolname="username"
                    placeholder="Login Name"
                    // onClick={onfocusInput}
                    onIonBlur={onBlurInput}
                    name="username"
                    type={'text'}
                    autoComplete="username"
                    value={`${username?.replace(' ', '')}`}
                    onKeyDown={onKeyDown}
                    onChange={changeName}
                    onIonChange={changeName}
                  />
                </IonItem>
                <IonItem className="login">
                  <IonInput
                    className="loginInput"
                    formcontrolname="password"
                    placeholder="Password"
                    // onClick={onfocusInput}
                    onIonBlur={onBlurInput}
                    name="password"
                    type="password"
                    onInput={(e) => e.target.value = e.target.value.slice(0, 16)}
                    value={password}
                    autoComplete="current-password"
                    onKeyDown={onKeyDown}
                    onIonChange={changePass}
                  />
                </IonItem>
              </IonList>
            )}
          </div>
        }
        {B2C && (
          <div className="forgot-pass-button" style={{textAlign: 'right', marginBottom: 2}}>
            <span className='link-text' onClick={() => actions.setTab(3)}>Log In with OTP?</span>
          </div>
        )}

        <div className="pop-up-content__buttons">
        
          <button
            onFocus={submit}
            onClick={submit}
            className="pushable log-in"
            disabled={!username || !password || loading}
          >
            <span className="shadow"></span>
            <span className="edge "></span>
            <span className="front refreshBtn">Log in
            {loading && <span className='loadingLogIn'></span>}
            </span>
          </button>
        </div>
        <div className="pop-up-content__buttons" style={{padding: 0}}>
          <button
            onClick={()=>{loginWithDemoId();}}
            className="pushable log-in"
            // style={{marginTop: 10}}
          >
            <span className="shadow"></span>
            <span className="edge "></span>
            <span className="front refreshBtn" style={{color: '#000'}}>Log in With Demo Id
            {loading && <span className='loadingLogIn'></span>}
            </span>
          </button>
        </div>
        
        {B2C &&
          <div className='registerWith-content'>
            {/* <div className="register-chose">Or Log in with</div>
            <div className='registerWith-content-btns'>
              <div className="register-button" onClick={() => glogin()}>
                <img className="icon" src={GoogleIcon} alt="" />
                <span className="register-button-link">Google</span>
              </div>
              <div 
                className="register-button" 
                onClick={() =>{window.location.href = `https://lotus365.authlink.me/?redirectUri=http://${window.location.host}`}}
              >
                <img className="icon" src={WhatsappIcon} alt="" />
                <span className="register-button-link">Whatsapp</span>
              </div>
            </div> */}

            <div className="link-text">
              New User? <span onClick={() => {actions.setTab(2)}}>Create an account</span>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default connectLoginPopUp()(LogInStep)
