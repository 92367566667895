import React, { useEffect, useState, useRef } from 'react'
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonHeader,
} from '@ionic/react'
import { useSelector } from 'react-redux'
import './DepositTimer.scss'
import rupyIcon from '../../assets/images/rupyIcon.svg'
import Done from '../../assets/images/transactionDoneIcon.svg'
import Toolbar from '../../components/Toolbar/Toolbar'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
// import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { connectDepositTimer } from './DepositTimer.connect'
import { useDispatch } from 'react-redux'

import { useHistory } from 'react-router'
import axios from 'axios'
import RenderTime from './renderTime'
import {
  useIonViewWillEnter,
  useIonViewWillLeave,
  useIonViewDidLeave,
} from '@ionic/react'
import { setReq } from '../../store/deposit/deposit.slice'
import { BASE_DEV_URL } from '../../config/api.config'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'

const DepositTimer = ({ user, actions, initialOpen, elementsParams, ...props }) => {
  const history = useHistory()
  const depositReq = useSelector((state) => state.deposit)

  const token = localStorage.getItem('id_token')
  const [isDone, setIsDone] = useState(false)
  const [countDownTime, setCountDownTime] = useState(59)
  const [reqResponse, setReqResponse] = useState()
  const dispatch = useDispatch()

  const updateDepositRequest = async (id) => {
    if (id) {
      return await axios
        .get(
          BASE_DEV_URL + `/payments/userid/txns?requestId=${id}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          },
        )
        .then((response) => setReqResponse(response.data.result[0]))
        .catch((e) => {
          // const errorMsg = getErrorMessage(e)
          // throw errorMsg
        })
    }
  }

  const updatePgDepositRequest = async (id) => {
    if (id) {
      return await axios
        .get(
          BASE_DEV_URL + `/pg/get_pg_txn_details?transactionId=${id}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          },
        )
        .then((response) => setReqResponse(response.data.result))
        .catch((e) => {
          // const errorMsg = getErrorMessage(e)
          // throw errorMsg
        })
    }
  }

  let mainTimer
  let clearCountdown

  useEffect(() => {
    if (countDownTime > 0) {
      clearCountdown = setInterval(() => {
        setCountDownTime(countDownTime - 1)
      }, 1000)
      return () => clearInterval(clearCountdown)
    }
    // else {
    //   history.push('/transactions')
    // }
  }, [countDownTime, localStorage.getItem('page')])

  useEffect(() => {
    // if (depositReq && depositReq !== {}) {
    //   setDepositReq(depositReq);
    // }
    // setIsManual(depositReq?.depositMethod?.isManual)
    if (depositReq?.result?.requestId || depositReq?.result?.transactionId) {
      if (countDownTime % 2)
        if (depositReq?.depositMethod) {
          updateDepositRequest(depositReq.result?.requestId)
        } else {
          updatePgDepositRequest(depositReq.result?.transactionId)
        }
      if (countDownTime === 1 && depositReq?.depositMethod) {
        // history.push('/transactions')
        history.replace('/transactions')
      }
    }
  }, [depositReq, countDownTime])

  useEffect(() => {
    if (!depositReq?.depositMethod) {
      setCountDownTime(60)
    }
    if (depositReq?.result?.status) {
      if (depositReq?.result.status !== 'Pending') {
        setCountDownTime(1)
        setTimeout(() => {
          setIsDone(true)
          dispatch(setReq({}))
          clearInterval(mainTimer)
          clearInterval(clearCountdown)
        }, 2000)
      }
    }
    // } else {
    //
    // }
  }, [reqResponse])

  useIonViewWillEnter(() => {
    setCountDownTime(59)
  })

  useIonViewDidLeave(() => {
    // setCountDownTime(0)
    dispatch(setReq({}))
    setIsDone(false)
    clearInterval(mainTimer)
    clearInterval(clearCountdown)
    // console.log('ionic leave from timer dep page')
  })

  useIonViewWillLeave(() => {
    // setCountDownTime(0)
    dispatch(setReq({}))
    clearInterval(mainTimer)
    clearInterval(clearCountdown)
    // console.log('ionic leave from timer dep page')
  })

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  // console.log("iManual => " + depositReq?.depositMethod)
  // console.log("isDone => " + isDone)

  if (depositReq?.depositMethod?.isManual !== undefined && !depositReq?.depositMethod?.isManual && !isDone && reqResponse?.status === "Pending") {

    localStorage.setItem(depositReq?.result?.transactionId, JSON.stringify(depositReq));

    if(depositReq?.result?.redirectURL !== undefined)
      window.location.replace(depositReq?.result?.redirectURL)

    return <></>;

  } else {
    return (
      <IonPage>
        <IonHeader>
          <Toolbar />
        </IonHeader>
        <IonContent className="ChangePassword" fullscreen>
          <div className="timer-wrapper">
            {!depositReq?.depositMethod && isDone && (
              <>
                <div className="transactionDone">
                  <div className="transactionDone-id">
                    Transaction ID: {depositReq?.result.refrenceNo}
                  </div>
                </div>
              </>)}

            {
              (depositReq.depositMethod) && (
                <>
                  {!isDone ? (
                    <>
                      <div className="countdownDeposit">
                        <div className="mmmm">
                          <div className="countdownDeposit-number">
                            00:
                            {countDownTime >= 10 ? countDownTime : `0${countDownTime}`}
                          </div>
                          <svg className="countdownDeposit-svg">
                            <defs>
                              <linearGradient
                                id="timerGradient"
                                x1="1"
                                y1="0"
                                x2="0"
                                y2="0"
                              >
                                <stop
                                  className="stoPP"
                                  offset="0%"
                                  stopColor="#95A051"
                                />
                                <stop
                                  className="stoPPP"
                                  offset="100%"
                                  stopColor="#DFFD28"
                                />
                              </linearGradient>
                            </defs>

                            <circle
                              r="37"
                              cx="50"
                              cy="50"
                              className="countdownDeposit-circle"
                              stroke="url(#timerGradient)"
                            ></circle>
                          </svg>
                        </div>
                      </div>


                      {/* <svg className='hideSVG'>
              <defs>
                <linearGradient id="timerGradient" x1="1" y1="0" x2="0" y2="0">
                  <stop offset="0%" stopColor="#95A051" />
                  <stop offset="100%" stopColor="#DFFD28" />
                </linearGradient>
              </defs>
            </svg> */}
                      {/* <div className='ssss'>
              <CountdownCircleTimer
                isPlaying
                duration={59}
                size={84}
                strokeWidth={6}
                colors="url(#timerGradient)"
              >
                {RenderTime}
              </CountdownCircleTimer>
            </div>  */}

                      {/* <div id="countdown">
              <div id="countdown-number"></div>
              <svg class="countdown" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <circle r="90" cx="100" cy="100"></circle>
                <circle class="countdown-circle" r="90" cx="100" cy="100"></circle>
              </svg>
            </div> */}
                    </>
                  ) : (
                    <>
                      <div className="transactionDone">
                        <div className="transactionDone-id">
                          Transaction ID: {depositReq?.result?.refrenceNo}
                        </div>
                        <div className="transactionDone-date">
                          {reqResponse?.createdAt?.slice(0, 10)}{' '}
                          <span className="transactionDone-time">
                    {reqResponse?.createdAt?.slice(11, 19)}
                  </span>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )
            }


            <div className="timerDepositAmount">
              <img
                className="timerDepositAmount-currency"
                src={rupyIcon}
                alt="Rupy"
                loading={"lazy"}
              />
              <span className="timerDepositAmount-sum">
              {depositReq?.result?.amount
                ? getCurrencyFormat(depositReq?.result?.amount)
                : getCurrencyFormat(reqResponse?.amount)}
            </span>
            </div>
            {reqResponse?.status ? (
              <div
                className={`timerTransaction-status ${
                  reqResponse?.status === 'Pending'
                    ? 'pending'
                    : reqResponse?.status === 'Rejected'
                      ? 'rejected'
                      : 'approved'
                } `}
              >
                {reqResponse?.status}
              </div>
            ) : null}
            {
              (depositReq?.depositMethod) ? (
                <>
                  {!isDone ? (
                    <>
                      <div className="timerUTR">UTR: {depositReq?.result?.refrenceNo}</div>
                      <div className="timerAllDone">
                        All Done! Let us confirm your transaction.{' '}
                      </div>
                    </>
                  ) : (
                    <>
                      <img loading={"lazy"} src={Done} alt="" />
                      <div className="timerSuccess">Success</div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {(depositReq?.result?.status === 'Approved') && (
                    <>
                      <img loading={"lazy"} src={Done} alt="" />
                      <div className="timerSuccess">Success</div>
                    </>
                  )
                  }
                </>
              )
            }


            <div className="timerButtons">
              {width >= 1024 ? (
                <button
                  onClick={() => {
                    // history.push('/default-page')
                    history.replace('/default-page')
                  }}
                  className="pushable"
                >
                  <span className="shadow"></span>
                  <span className="edge"></span>
                  <span className="front">Home</span>
                </button>
              ) : (
                <button
                  className="timerButtons-item"
                  onClick={() => {
                    // history.push('/default-page')
                    history.replace('/default-page')
                    setIsDone(false)
                  }}
                >
                  Home
                </button>
              )}

              {!isDone ? (
                <>
                  {width >= 1024 ? (
                    <button
                      onClick={() => {
                        // history.push('/transactions')
                        history.replace('/transactions')
                      }}
                      className="pushable"
                    >
                      <span className="shadow"></span>
                      <span className="edge"></span>
                      <span className="front">Transactions</span>
                    </button>
                  ) : (
                    <button
                      className="timerButtons-item"
                      onClick={() => {
                        // history.push('/transactions')
                        history.replace('/transactions')
                      }}
                    >
                      Transactions
                    </button>
                  )}
                </>
              ) : null}
            </div>
          </div>
          {/*{width < 1024 && <FooterDesktop />}*/}
        </IonContent>
      </IonPage>
    )
  }


}

export default connectDepositTimer()(DepositTimer)
