import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetSearchResults, setSearchValue } from '../../store/search/search.slice';
import { requestSearchResults } from '../../store/search/search.thunks';
import { setNotifications, requestSaveOneClickSettings } from '../../store/user/user.thunks';
import { requestLogout } from '../../store/auth/auth.thunks'
import { setRuleRegulationPopUp } from '../../store/ruleRegulationPopup/ruleRegulationPopup.slice';
import { setIsNavigationOpen, setIsNavigationID, setIsNavigationName, setIsNavigationReset, setIsAnimatedMenu, setIsMoreDetailed, setIsAnimatedText, setSearchValues, setIsDetailedGroup, setRenderIonContent } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice';
import { setLoginNew, setTab } from '../../store/LoginNew/loginNew.slice'
import { requestSportPageEvent } from '../../store/sportPageEvent/sportPageEvent.thunks';
import { requestMessage } from "../../store/message/message.thunks";
import { setLoginNavigationData } from '../../store/loginNavigation/LoginNavigation.slice';
import { setCasinoType } from '../../store/casinoprovider/casinoprovider.slice';
import { setComingSoonModal } from '../../store/modals/comingSoonModal.slice';

export const connectToolbarDesktop = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      balance: state.balance,
      messages: state.message,
      notifications: state.user.settings.notifications,
      oneClick: state.user.settings.oneClickSettings,
      user: state.user,
      menu: state.menu,
      auth: state.auth,
      B2C: state.B2C,
      animatedText: state.navigationHeaderMenu.animatedText,
      searchValue: state.navigationHeaderMenu.searchValue,
      isNavigationName: state.navigationHeaderMenu.navigationName,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSaveOneClickSettings,
      requestSearchResults, 
      setSearchValue,
      resetSearchResults,
      setNotifications,
      requestLogout,
      setIsNavigationOpen,
      setIsNavigationID,
      setIsNavigationName,
      setIsNavigationReset,
      setIsAnimatedMenu,
      setLoginNew,
      setTab,
      setIsMoreDetailed,
      setIsAnimatedText,
      setSearchValues,
      requestSportPageEvent,
      setIsDetailedGroup,
      setRenderIonContent,
      requestMessage,
      setRuleRegulationPopUp,
      setCasinoType,
      setLoginNavigationData,
      setComingSoonModal
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
