import './pop-upImage.scss'
import React, { useState, useEffect } from 'react'
import { createPortal } from "react-dom";
import CloseIcon from '../../../assets/images/closeLogin.svg'


const modalRoot = document.querySelector("#modal-root");


const PopupImage = ({ open, imgFile, onDidDismiss }) => {
    const [imgPreview, setImgPreView] = useState();
    const height = window.innerHeight;

    useEffect(() => {
        let blob;

        if (open && imgFile) {
            blob = URL.createObjectURL(imgFile);
            if (blob) setImgPreView(blob);
        }

        return () => {
            if (blob) {
                URL.revokeObjectURL(blob);
            }
        };
    }, [open, imgFile]);


  return createPortal(
    <>

        {open && 
            <div className="editPopUpHelp">
            <div
                className="pop-upHelp_wrapper"
                onClick={(e) => {
                    if (e.currentTarget === e.target) {
                        onDidDismiss(false)
                    }
                }}
            >
                <div className={"pop-image"}>
                    <div className="pop-upHelp-content">
                        <div className='pop-upHelp-content_title-big'>Image Preview</div>
                        <div 
                            className='close-container'
                            onClick={()=>{ onDidDismiss(false)}}
                        >
                            <img 
                                src={CloseIcon}
                                
                            />
                        </div>

                        <div style={{marginBottom: 10}}>
                            <img 
                                loading={"lazy"} 
                                src={imgPreview} 
                                alt="preview deposit ss"
                                className="preview-image"
                                style={{maxHeight: (80 * height)/100}}
                            />
                        </div>
                
                
               
                        </div>

                    </div>
                </div>
            </div>
        }
    </>,
    modalRoot
  )
}

export default PopupImage
