import './pop-up.scss'
// import { IonButton, IonImg, IonPopover } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setDetailedDepositTransaction } from '../../../store/detailedDepositTransaction/detailedDepositTransaction.slice'
import { useHistory } from 'react-router'

import { toastr } from 'react-redux-toastr'
import { BASE_DEV_URL } from '../../../config/api.config'
import { useIonViewWillEnter } from '@ionic/react'
import getErrorMessage from '../../../helpers/getErrorMessage.helper'
import cancelIcon from '../../../assets/images/transactions/cancelIcon.svg'


const PopUP = ({
  content,
  open,
  onDidDismiss,
  data,
  refresh,
  scrollPosition,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = localStorage.getItem('id_token')
  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth

  const [remark, setRemark] = useState('')
  const requestId = data.requestId
  const [utr, setUTR] = useState(data.utr)
  const [amount, setAmount] = useState(data.amount)
  const [message, setMessage] = useState('')

  // console.log('scrollPosition', scrollPosition)

  const canceledData = {
    remark,
    requestId,
  }

  const editedData = {
    requestId: `${requestId}`,
    UTR: utr ? utr : data.utr,
    amount: `${amount}`,
  }

  const cancelOneTransactionRequest = async () => {
    return await axios
      .post(
        BASE_DEV_URL +
          `/payments/userid/cancel-request?requestId=${data.requestId}`,
        canceledData,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => console.log(response.data.result))
      .then(updateOneTransactionRequest())
      .then(setRemark())
      .catch((e) => {
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      })
    // history.push('/transactions')
    // updateOneTransactionRequest(),
    // onDidDismiss(false)
  }

  const editOneTransactionRequest = async () => {
    return await axios
      .post(
        BASE_DEV_URL +
          `/payments/userid/update-deposit-request?requestId=${data.requestId}`,
        editedData,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => toastr.success('Success'))
      .then(onDidDismiss(false))
      .catch((error) => {
        console.log(error?.response?.data.result)
        if (error?.response?.data.result === 'Already updated.') {
          toastr.info('No changes')
        } else if (error?.response?.data?.success === false) {
          toastr.error('Error', error?.response?.data?.error)
        } else {
          toastr.success('Success')
        }
      })
  }

  const updateOneTransactionRequest = async () => {
    return await axios
      .get(BASE_DEV_URL + `/payments/userid/txns?requestId=${data.requestId}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) =>
        dispatch(setDetailedDepositTransaction(response.data.result[0]))
      )
      .catch((e) => {
        const errorMsg = getErrorMessage(e)
        throw errorMsg
      })
  }


  const onValidationAmount = (e) => {
    if (e < 0) {
      setAmount(e)
      setMessage("Amount can't be negative")
    } else if (e < 1) {
      setAmount(e)
      setMessage("Amount can't be empty")
    } else {
      setAmount(e)
      setMessage('')
    }
  }

  return (
    <>
      {open && (
        <>
          {content ? (
            <div className="editPopUp">
              <div
                className="pop-up_wrapper"
                onClick={(e) => {
                  if (e.currentTarget === e.target) {
                    onDidDismiss(false)
                  }
                }}
                style={{ top: scrollPosition + 'px' }}
              >
                <div className="pop-up">
                  <div className="pop-up-content">
                    <div className='pop-up-content_info'>
                      <span className='pop-up-content_info-title'>Edit Deposit Details</span>
                      <img loading={"lazy"} src={cancelIcon} onClick={() => {
                          onDidDismiss(false)
                          refresh()
                        }} alt="" />
                    </div>
                    <div className="pop-up-content_title">
                      Reference No: {data.requestId}
                    </div>
                    <div className="text-field">
                      <div className='text-field_container'>
                        <label className="text-field_container__label" htmlFor="UTR">
                        UTR
                      </label>
                      <input
                        onInput={(e) => setUTR(e.target.value)}
                          className="text-field_container__input"
                        type="text"
                        name="UTR"
                        id="UTR"
                        placeholder="UTR"
                        defaultValue={`${data.transactionId ? data.transactionId : 0}`}
                        autoCapitalize="off"
                      ></input>
                      </div>
                      <div className='text-field_container'>
                        <label className="text-field_container__label" htmlFor="Amount">
                        Amount
                      </label>
                        <input
                          onInput={(e) => onValidationAmount(e.target.value)}
                          className="text-field_container__input"
                          type="number"
                          name="Amount"
                          id="Amount"
                          placeholder="Amount"
                          defaultValue={`${data.amount ? data.amount : 0}`}
                          autoCapitalize="off"
                        ></input>
                      </div>
                      <div className="validation-message">{message}</div>
                    </div>
                  </div>

                  <div className="pop-up-content__buttons">
                    {/* <div
                      className="pop-up-content__buttons-send"
                      onClick={() => {
                        editOneTransactionRequest()
                        onDidDismiss(false)
                        setTimeout(() => {
                          updateOneTransactionRequest()
                          refresh()
                        }, 500)

                        // history.push('/transactions')
                        // toastr.success('Success')
                        console.log('NEWamount', amount)
                        console.log('NEWUTR', utr)
                        console.log('requestId', requestId)
                      }}
                    >
                      Submit
                    </div> */}
                    {width >= 1024 ? (
                      <button
                        onClick={() => {
                          // editOneTransactionRequest()
                          onDidDismiss(false)
                          setTimeout(() => {
                            // updateOneTransactionRequest()
                            refresh()
                          }, 500)
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge refreshBtn"></span>
                        <span className="front refreshBtn">Submit</span>
                      </button>
                    ) : (
                      <div
                        onClick={() => {
                          // editOneTransactionRequest()
                          onDidDismiss(false)
                          setTimeout(() => {
                            // updateOneTransactionRequest()
                            refresh()
                          }, 500)
                        }}
                        className="pop-up-content__buttons-send"
                      >
                        Submit Changes
                      </div>
                    )}

                    {/* <div
                      className="pop-up-content__buttons-cancel"
                      onClick={() => {
                        onDidDismiss(false)
                        refresh()
                      }}
                    >
                      Cancel
                    </div> */}
                    {width >= 1024 ? (
                      <button
                        onClick={() => {
                          onDidDismiss(false)
                          refresh()
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge cancelBtn"></span>
                        <span className="front cancelBtn">Cancel</span>
                      </button>
                    ) : null
                    // (
                    //   <div
                    //     className="pop-up-content__buttons-cancel"
                    //     onClick={() => {
                    //       onDidDismiss(false)
                    //       refresh()
                    //     }}
                    //   >
                    //     Cancel
                    //   </div>
                    // )
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="cancelPopUp">
              <div
                className="pop-up_wrapper"
                onClick={(e) => {
                  if (e.currentTarget === e.target) {
                    onDidDismiss(false)
                  }
                }}
                style={{ top: scrollPosition + 'px' }}
              >
                <div className="pop-up">
                  <div className="pop-up-content">
                    <div className='pop-up-content_info'>
                      <span className='pop-up-content_info-title'>Cancel Deposit</span>
                      <img loading={"lazy"} src={cancelIcon} onClick={() => {
                          onDidDismiss(false)
                          refresh()
                        }} alt="" />
                      </div>
                    {/* <div className="pop-up-content_title">Why cancel?</div> */}
                    <div className="pop-up-content_text">
                      <textarea
                        placeholder='Please Enter Reason for cancelling (Optional)'
                        onChange={(e) => setRemark(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  <div className="pop-up-content__buttons">
                    {width >= 1024 ? (
                      <button
                        onClick={() => {
                          // cancelOneTransactionRequest()
                          onDidDismiss(false)
                          // toastr.success('Your message has been sent.')
                          setTimeout(() => {
                            // updateOneTransactionRequest()
                            refresh()
                          }, 500)
                        }}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge refreshBtn"></span>
                        <span className="front refreshBtn">Send</span>
                      </button>
                    ) : (
                      <div
                        className="pop-up-content__buttons-send"
                        onClick={() => {
                          cancelOneTransactionRequest()
                          // updateOneTransactionRequest()
                          onDidDismiss(false)
                          toastr.success('Your message has been sent.')
                          setTimeout(() => {
                            updateOneTransactionRequest()
                            refresh()
                          }, 500)
                          // history.push('/transactions')
                        }}
                      >
                        Send Changes
                      </div>
                    )}

                    {width >= 1024 ? (
                      <button
                        onClick={() => onDidDismiss(false)}
                        className="pushable"
                      >
                        <span className="shadow"></span>
                        <span className="edge cancelBtn"></span>
                        <span className="front cancelBtn">Cancel</span>
                      </button>
                    ) : null
                    // (
                    //   <div
                    //     className="pop-up-content__buttons-cancel"
                    //     onClick={() => onDidDismiss(false)}
                    //   >
                    //     Cancel
                    //   </div>
                    // )
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default PopUP
