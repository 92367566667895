import React, { useEffect, useRef, useState } from "react";
import { IonContent, IonPage, IonHeader, IonToolbar, IonButton, IonIcon, useIonViewWillLeave, withIonLifeCycle} from "@ionic/react";
import { connectCasinoLive } from "./CasinoLive.connect";
import { useHistory, useParams } from "react-router-dom";
import goBackIcon from "../../assets/images/goback.svg";
import userIcon from '../../assets/images/toolbar-user-icon.svg';
import walletIcon from '../../assets/images/wallet.svg';
import SlotsCasinoPopUp from "../../components/SlotsCasinoPopup";


const CasinoLiveNew = ({ user, balance, slotCasinoPopUp, actions, elementsParams }) => {
    const history = useHistory();
    const { gameName } = useParams();
    const refIframe = useRef(null);
    const [url, setUrl] = useState('');
    const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth;

    useEffect(() => {
        if(!user?.verifytoken) {
            history.push("/default-page");
        }
    }, [user])


    useEffect(() => {
        if(!!user?.verifytoken) {
            if(gameName == 'Aviator') {
                setUrl(`https://live.fantasylineups.com/?token=${user?.verifytoken}`);
            }
        }

        return() => {
            setUrl('');
        }

    }, [gameName, user]);


    useIonViewWillLeave(() => {
        setUrl('');
    });

    // useEffect(() => {
    //     return () => {
    //         if (width >= 1024 && !history.location.pathname.includes("/casino_live_new")) {
    //             setUrl('');
    //         }
    //     }
    // }, []);


    const endCasino = () => {
        history.goBack();
        setUrl('');
    };


    return (width >= 1024 ? (
        <IonPage className="CasinoGames" style={width >= 1024 ? { overflow: `hidden!important` } : {}}>
            <IonHeader>
                <IonToolbar className="CasinoGames__toolbar">
                    <div className="CasinoGames__toolbar__wrapper">
                        <div className="ion-toolbar__left">
                            <IonButton
                                className="menu-btn"
                                mode="md"
                                fill="clear"
                                onClick={endCasino}
                            >
                                <IonIcon className="menu-icon go-back-icon" src={goBackIcon} />
                            </IonButton>
                            <div className="headerDesktop-content__logo-img" alt="" />
                        </div>

                        <div className="ion-toolbar__left" style={{gap: '10px', marginRight: '20px'}}>
                            {(!!user && !!user?.details)&&
                            <div className="ion-toolbar__left">
                                <IonIcon
                                className="menu-acc-icon"
                                slot="start"
                                icon={userIcon}
                                />
                                <p style={{marginLeft: '5px'}}>{user?.details?.username}</p>

                            </div>
                            }

                            {(!!balance)&&
                            <div className="ion-toolbar__left">
                                <IonIcon
                                    className="menu-acc-icon"
                                    slot="start"
                                    icon={walletIcon}
                                />
                                <p style={{marginLeft: '5px'}}>{balance?.balance || 0}</p>
                            </div>
                            }
                        </div>
                    </div>
                </IonToolbar>
            </IonHeader>
            <div>
            {!!url && <iframe
                className="CasinoGames__content__video"
                ref={refIframe}
                allowFullScreen
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                // scrolling="yes"
                title="casino live"
                src={url}
            >
            </iframe>}
            </div>

            {slotCasinoPopUp?.isOpen && <SlotsCasinoPopUp/>}
        </IonPage>
        ) : (
        <IonPage className="CasinoGames" style={width >= 1024 ? { paddingTop: `135px` } : {}}>
            <IonHeader>
                <IonToolbar className="CasinoGames__toolbar">
                    <div className="CasinoGames__toolbar__wrapper" style={{alignItems: 'center'}}>
                    <div className="ion-toolbar__left">
                        <IonButton
                        className="menu-btn"
                        mode="md"
                        fill="clear"
                        onClick={endCasino}
                        >
                        <IonIcon className="menu-icon go-back-icon" src={goBackIcon} />
                        </IonButton>
                        <div className="toolbar-logo" alt="" />
                    </div>

                    <div className="ion-toolbar__left" style={{display: 'block', marginRight: '10px'}}>
                        {(!!user && !!user?.details)&&
                        <div className="ion-toolbar__left">
                            <IonIcon
                            className="menu-acc-icon"
                            slot="start"
                            icon={userIcon}
                            />
                            <p style={{margin: '0 0 0 5px', fontSize: 14}}>{user?.details?.username}</p>

                        </div>
                        }

                        {(!!balance)&&
                        <div className="ion-toolbar__left">
                            <IonIcon
                            className="menu-acc-icon"
                            slot="start"
                            icon={walletIcon}
                            />
                            <p style={{margin: '5px 0 0 5px', fontSize: 14}}>{balance?.balance || 0}</p>

                        </div>
                        }
                    </div>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
            {!!url && <iframe
                className="CasinoGames__content__video"
                ref={refIframe}
                allowFullScreen
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                // scrolling="yes"
                title="casino live"
                src={url}>
            </iframe>}
            {/*<FooterDesktop />*/}
            </IonContent>
            {slotCasinoPopUp?.isOpen && <SlotsCasinoPopUp/>}
        </IonPage>
        )
    );
};

export default withIonLifeCycle(connectCasinoLive()(CasinoLiveNew));
