import '../../pages/Withdrawal/SuccessWithdrawPopoup/pop-upDelete.scss';
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import SuccessWithdrawIcon from '../../assets/images/SuccessWithdraw.svg';
import horseIcon from '../../assets/images/sidebar/horse-riding.svg';
import PoliticsIcon from '../../assets/images/sidebar/politics-icon.png';
import GreyhoundIcon from '../../assets/images/sidebar/greyhound-racing.svg';
import KabaddiIcon from '../../assets/images/sidebar/kabaddi-icon.png';
import BinaryIcon from '../../assets/images/sidebar/binary-icon.svg';
import MartialArtsIcon from '../../assets/images/sidebar/martial-arts-icon.png';
import VolleyballIcon from '../../assets/images/sidebar/volleyball-icon.png';
import IceHockeyIcon from '../../assets/images/sidebar/ice-hockey.png';
import BasketballIcon from '../../assets/images/sidebar/basketball-icon.png';
import BaseballIcon from '../../assets/images/sidebar/baseball-icon.png';
import DartsIcon from '../../assets/images/sidebar/darts.png';
import FutsalIcon from '../../assets/images/sidebar/futsal-icon.png';
import TableTennisIcon from '../../assets/images/sidebar/tabletennis-icon.png';
import RugbyIcon from '../../assets/images/sidebar/rugby-icon.png';
import { setCloseComingSoonModal, setComingSoonModal } from '../../store/modals/comingSoonModal.slice';

const imgTypeArr = [
    { type: 'Horse Racing', img: horseIcon},
    { type: 'Politics', img: PoliticsIcon},
    { type: 'Greyhound Racing', img: GreyhoundIcon},
    { type: 'Kabaddi', img: KabaddiIcon},
    { type: 'Binary', img: BinaryIcon},
    { type: 'Mixed Martial Arts', img: MartialArtsIcon},
    { type: 'Volleyball', img: VolleyballIcon},
    { type: 'Ice Hockey', img: IceHockeyIcon},
    { type: 'Basketball', img: BasketballIcon},
    { type: 'Baseball', img: BaseballIcon},
    { type: 'Darts', img: DartsIcon},
    { type: 'Futsal', img: FutsalIcon},
    { type: 'Table Tennis', img: TableTennisIcon},
    { type: 'Table Tennis', img: TableTennisIcon},
    { type: 'Rugby', img: RugbyIcon},
]

const ComingSoonPopup = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.comingSoonModal.isOpen);
  const type = useSelector((state) => state.comingSoonModal.type);
  const [selectedImg, setSelectedImage] = useState(horseIcon);

  useEffect(() => {
    if(type) {
        const img = imgTypeArr.find(item => item.type == type)['img'];
        setSelectedImage(img);
    }
  }, [type]);

//   const isComingSoonPopupOpen = 
//   const history = useHistory()
//   const token = localStorage.getItem('id_token')

  return (
    <>
      
        { open && <div className="editPopUpDelete">
          <div
            className="pop-upDelete_wrapper"
            style={{position: 'fixed'}}
            // onClick={(e) => {
            //   if (e.currentTarget === e.target) {
            //     onDidDismiss(false)
            //   }
            // }}
          >
            <div className= "pop-upDelete" style={{left: '50%'}}>
              <div className="pop-upDelete-content">
                <img src={selectedImg} style={{width: 100, height: 100}} />
                <div className='successPopUpTitle'>Coming Soon!</div>
                <div className='successPopUpSubTitle'>{type} Will Be Available Soon</div>
              </div>

              <div className="pop-upDelete-content__buttons">
                <div
                  className="successPopUpButton"
                  onClick={() => {
                    dispatch(setCloseComingSoonModal());

                    // onDidDismiss(false);

                    // setTimeout(() => {
                    //   history.push('/default-page')
                    // }, 300)
                  }}
                  style={{cursor: 'pointer'}}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default ComingSoonPopup;
